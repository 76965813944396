export const Theme = {
  white: "#fff",
  black: "#000000",
  secondaryColor: "#f3f7fe",
  primaryColor: "#0b5fe3"
};

export const FontFamily = {
  SemiBold: "Gilroy-SemiBold",
  Medium: "Gilroy-Medium",
  Bold: "Gilroy-Bold",
  ExtraBoldItalic: "Gilroy-ExtraBoldItalic",
  Regular: "Gilroy-Regular"
};

export const Size = {
  xxxl: "30px",
  xxl: "26px",
  xl: "24px",
  bxl: "22px",
  bl: "20px",
  lg: "18px",
  base: "16px",
  sm: "14px",
  xs: "12px",
  NavbarWidth: "200px",
  HeaderWidth: "90px",
  HeaderHeight: "95px"
};
