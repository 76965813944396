import React, { useState, useEffect } from "react";
// import { CategoryRowContainer } from "./FlavorRow.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import Switch from "components/common/Switch";
import { withRouter } from "react-router-dom";
// import CategoryDrawer from "components/DrawerModules/CategoryDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as flavorActions from "store/Flavor/actions";
import swal from "sweetalert";
import FlavorDrawer from "components/DrawerModules/FlavorDrawer";
import { ListRow } from "components/UserRow/UserRow.styled";
import { formatDate } from "utility/helperFunctions";

function FlavorRow(props) {
  let data = props.data;
  const [showFlavorDrawer, setShowFlavorDrawer] = useState(false);
  const [activeState, setActiveState] = useState(props.data.isActive);
  useEffect(() => {
    setActiveState(props.data.isActive);
  }, [props.data.isActive]);
  function changeActiveStatus() {
    let payload = {
      isActive: !activeState
    };

    props.updateFlavorById(data._id, payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        props.getFlavorList();
      }
    });
    setActiveState(!activeState);
  }

  //   function redirectToDetail(data) {
  //     props.history.push({
  //       pathname: `/categories/${data.id}`
  //     });
  //   }

  function editFlavor(e) {
    setShowFlavorDrawer(true);
    e.stopPropagation();
  }

  return (
    <div>
      <ListRow>
        <TitleContainer ad>
          {/* <img
            alt="ad"
            src={data.marker && data.marker.mediaUrl}
            className="ad-marker"
          />
          <img
            alt={data.app.name}
            src={data.app.icon && data.app.icon.mediaUrl}
            className="image"
          /> */}
          <div>
            <div>{data.name}</div>
            <div className="subtext">{formatDate(data.createdAt)}</div>
          </div>
        </TitleContainer>
        <div style={{ width: "25%" }}>{data.applicationId}</div>
        <div style={{ width: "25%" }}>{data.platform}</div>
        <div style={{ width: "24%" }}>
          <Flexbox justifyContent="flex-end">
            <Switch state={activeState} changeStatus={changeActiveStatus} />
            <img
              src={require("static/images/edit.svg")}
              alt="edit"
              className="ml-25 pointer"
              height="13px"
              width="13px"
              onClick={(e) => editFlavor(e)}
            />
          </Flexbox>
        </div>
      </ListRow>
      {showFlavorDrawer && (
        <FlavorDrawer
          show={showFlavorDrawer}
          closeDrawer={() => {
            setShowFlavorDrawer(false);
          }}
          update={data._id}
          name={data.name}
          // img={data.icon}
          // color={data.color}
          // subCategories={data.subCategories.map((item) => {
          //   return { name: item.name, id: item.id };
          // })}
        />
      )}
    </div>
  );
}

export default connect(
  ({ Flavor }) => ({ ...Flavor }),
  (dispatch) => bindActionCreators({ ...flavorActions }, dispatch)
)(withRouter(FlavorRow));
