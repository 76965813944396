import styled from "styled-components";

export const ImageCarouselContainer = styled.div`
  width: 100%;
  height: ${props => (props.banner ? "145px" : "100px")};
  position: relative;
  border-radius: 6px;
  .slick-list {
    height: ${props => (props.banner ? "145px" : "100px")} !important;
  }
  .uploaded-img {
    border-radius: 6px;
    object-fit: contain;
    width: 100%;
    height: ${props => (props.banner ? "145px" : "100px")};
  }
  .image-carousel {
    position: relative;
  }
  .slick-arrow {
    display: none !important;
  }
  .carousel-dots {
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex !important;
    justify-content: center;
    list-style-type: none;
    color: transparent !important;
    button {
      width: 18px;
      height: 2px;
      opacity: 0.4;
      border-radius: 1px;
      background-color: #ffffff;
      font-size: 0;
    }
    .slick-active {
      button {
        opacity: 1;
      }
    }
  }
`;
