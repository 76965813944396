import React, { useState, useEffect } from "react";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import { sectionHeads } from "utility/sectionsUtitlity";
import SectionsRow from "components/SectionsRow";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sectionActions from "store/Section/actions";
import * as applicationActions from "store/Application/actions";
import * as advertisementActions from "store/Advertisement/actions";
import Loader from "components/common/Loader";

function Sections(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    props.getSectionRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    !props.ApplicationList.length && props.getFullApplicationListRequest();
    !props.AdvertisementList.length && props.getAdvertisementListRequest();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Sections <span>(3)</span>
        </h1>
      </Flexbox>
      {loading ? (
        <Loader />
      ) : (
        sectionHeads &&
        props.Section &&
        sectionHeads.map((section) => {
          let data =
            props.Section[section.sectionKey] &&
            props.Section[section.sectionKey].items;

          return (
            <SectionsRow
              title={section.title}
              data={data ? data : []}
              sectionKey={section.sectionKey}
              key={section.sectionKey}
            ></SectionsRow>
          );
        })
      )}
    </Container>
  );
}

export default connect(
  ({ Section, Application, Advertisement }) => ({
    ...Section,
    ...Application,
    ...Advertisement
  }),
  (dispatch) =>
    bindActionCreators(
      { ...sectionActions, ...applicationActions, ...advertisementActions },
      dispatch
    )
)(Sections);
