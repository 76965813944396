import React from "react";
import { Container, NavContainer } from "./SideNavbar.styled";
import { withRouter } from "react-router-dom";
import { menu } from "utility/sideNavUtility";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as searchActions from "store/Search/actions";

function SideNavbar(props) {
  const isActive = (path, shouldInclude) => {
    if (shouldInclude) {
      return props.location.pathname.includes(path);
    }
    return props.location.pathname === path;
  };
  const redirect = (path) => {
    props.setSearchRequest("");
    if (props.location.pathname !== path) props.history.push(path);
  };
  return (
    <Container>
      <NavContainer active>
        <img
          alt="messenger-pro"
          src={require("static/images/navbar-messenger-pro.svg")}
          className="messenger-pro-icon"
        />
        <div className="heading">Messenger Pro</div>
      </NavContainer>
      {menu.map((item, index) => {
        return (
          <NavContainer
            key={index}
            onClick={() => {
              redirect(item.path);
            }}
            active={isActive(item.path, item.include)}
          >
            <img className="icons" src={item.img} alt={item.alt} />
            <div className="title">{item.alt}</div>
            <div className="border"></div>
          </NavContainer>
        );
      })}
    </Container>
  );
}

export default connect(
  ({ Search }) => ({ ...Search }),
  (dispatch) => bindActionCreators({ ...searchActions }, dispatch)
)(withRouter(SideNavbar));
