import React, { useEffect, useRef } from "react";
import { ModalContent, ModalHead } from "./Modal.styled";
import { BlurBackground } from "static/coreStyles/global.styled";
function Modal(props) {
  // eslint-disable-next-line
  const ModalNode = useRef(null);
  const BackgroundNode = useRef(null);

  function handleClick(e) {
    if (
      BackgroundNode.current &&
      BackgroundNode.current.contains(e.target) &&
      ModalNode.current &&
      !ModalNode.current.contains(e.target)
    ) {
      hideModal();
    }
  }
  function hideModal() {
    props.closeModal();
  }
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("click", handleClick, false);
    // returned function will be called on component unmount
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("click", handleClick, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BlurBackground
      show={props.show}
      ref={(node) => (BackgroundNode.current = node)}
    >
      <ModalContent
        ref={(node) => (ModalNode.current = node)}
        banner={props.banner}
      >
        <ModalHead>
          <div>
            {props.title}
            {props.subtitle && (
              <div className="modal-subtitle">{props.subtitle}</div>
            )}
          </div>
          <img
            alt="cross"
            src={require("static/images/cancel-circle.svg")}
            className="pointer"
            height="20px"
            width="20px"
            onClick={() => hideModal()}
          />
        </ModalHead>
        {props.children}
      </ModalContent>
    </BlurBackground>
  );
}
export default Modal;
