import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";
import background from "static/images/login-bg.svg";

export const Container = styled.div`
  background: url(${background});
  background-color: ${Theme.white};
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  display: flex;
  align-items: center;
`;
export const SubContainer = styled.div`
  margin: auto 0 auto 112px;
  .title {
    font-family: ${FontFamily.ExtraBoldItalic};
    font-weight: 800;
    line-height: 1.3;
    letter-spacing: 0.16px;
    margin: 10px 0 70px;
    font-size: ${Size.xxxl};
    color: ${Theme.primaryColor};
  }
  .head {
    font-family: ${FontFamily.SemiBold};
    font-size: 45px;
    font-weight: 600;
    color: #212121;
    margin: 0 0 7px;
  }
  .sub-heading {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.base};
    font-weight: 500;
    color: #707070;
    margin: 0 0 24px;
  }
  .input-head {
    opacity: 0.5;
    font-family: ${FontFamily.Medium};
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    margin: 0 0 12px;
  }
  .login-input {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: solid 1px rgba(26, 65, 106, 0.12);
    margin-bottom: 20px;
    padding: 15px 14px;
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.base};
    color: #212121;
    ::placeholder {
      opacity: 0.2;
      font-family: ${FontFamily.SemiBold};
      font-size: ${Size.base};
      font-weight: 600;
      color: #212121;
    }
  }
  .login-validated {
    border: solid 1px #1cae7c;
  }
  .login-error {
    border: solid 1px #f27474;
  }
  .login {
    width: 210px;
    height: 50px;
    border-radius: 6px;
    background-color: ${Theme.primaryColor};
    margin-top: 46px;
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.lg};
    font-weight: 600;
    letter-spacing: -0.08px;
    text-align: center;
    color: ${Theme.white};
    display: block;
  }
  .forget {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    color: #3960f2;
    position: absolute;
    right: 14px;
    top: 17px;
  }
  .relative {
    position: relative;
  }
`;

export const RememberMe = styled.div`
  .container {
    color: #707070;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    font-size: ${Size.sm};
    font-family: ${FontFamily.Regular};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0.3;
    border-radius: 4px;
    border: solid 1.5px #707070;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
