import { storage as LocalStorage } from "services/config/storage";

export const validateEmail = (email) => {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const getMonth = (numericValue) => {
  switch (numericValue) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sept";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "";
  }
};

export function formatDate(date) {
  date = new Date(date);
  if (date) {
    return `Created at ${date.getDate()} ${getMonth(
      date.getMonth()
    )}, ${date.getFullYear()}`;
  } else return "";
}

export function getScope(singleCountry) {
  let isGlobal = LocalStorage.fetch.countryData()
    ? LocalStorage.fetch.countryData().value
    : "Global";
  isGlobal = isGlobal === "Global";
  let scope = { isGlobal: isGlobal };
  if (!isGlobal) {
    if (singleCountry) {
      scope.countryCode = LocalStorage.fetch.countryData().value;
    } else {
      scope.countries = [LocalStorage.fetch.countryData().value];
    }
  }
  return scope;
}
