// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer {
  cursor: pointer; }

.ml-25 {
  margin-left: 25px; }

.none {
  display: none; }

.swal-button {
  color: white;
  background-color: #f27474;
  border-radius: 6px; }

.swal-footer {
  text-align: center; }

.swal-button:focus {
  box-shadow: none; }

.swal-button:not([disabled]):hover {
  background-color: #f27474;
  border-radius: 6px; }

.loader {
  width: 24px;
  height: 24px; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAaA;EACE,eAAe,EAAA;;AAEjB;EACE,iBAAiB,EAAA;;AAEnB;EACE,aAAa,EAAA;;AAEf;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB,EAAA;;AAEpB;EACE,kBAAkB,EAAA;;AAEpB;EACE,gBAAgB,EAAA;;AAElB;EACE,yBAAyB;EACzB,kBAAkB,EAAA;;AAEpB;EACE,WAAW;EACX,YAAY,EAAA","sourcesContent":["$primaryColor: #f5f5f6;\n$secondaryColor: #da1e47;\n\n$xxxl: 30px;\n$xxl: 26px;\n$xl: 24px;\n$bxl: 22px;\n$bl: 20px;\n$lg: 18px;\n$base: 16px;\n$sm: 14px;\n$xs: 12px;\n\n.pointer {\n  cursor: pointer;\n}\n.ml-25 {\n  margin-left: 25px;\n}\n.none {\n  display: none;\n}\n.swal-button {\n  color: white;\n  background-color: #f27474;\n  border-radius: 6px;\n}\n.swal-footer {\n  text-align: center;\n}\n.swal-button:focus {\n  box-shadow: none;\n}\n.swal-button:not([disabled]):hover {\n  background-color: #f27474;\n  border-radius: 6px;\n}\n.loader {\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
