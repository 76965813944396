import { Flavor } from "services/Flavor";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in Flavor action : ", err);
};

export const getFlavorList = () => async (dispatch) => {
  try {
    let data = await Flavor.getFlavorList();
    dispatch(mutation.setFlavorList(data.result));
    return data;
  } catch (error) {
    handleError();
    return error;
  }
};

export const createFlavor = (payload) => async (dispatch) => {
  try {
    let data = await Flavor.createFlavor(payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateFlavorById = (id, payload) => async (dispatch) => {
  try {
    let data = await Flavor.updateFlavorById(id, payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};
