import styled from "styled-components";
import { Theme, FontFamily, Size } from "static/coreStyles/theme.styled";

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  overflow: auto;
  height: 380px;
  padding: 10px 25px 17px 25px;
  .banner-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100% - 48px) / 4);
    height: 90px;
    margin-right: 12px;
    margin-top: 12px;
    padding: 5px;
    border: 2px solid #f8f8f8;
  }
  .selected-banner {
    border: 2px solid ${Theme.primaryColor};
  }
  .banner-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
  .button-holder {
    margin: 13px 0 0 20px;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: solid 1px rgba(26, 65, 106, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plus {
    background-color: rgba(160, 181, 205, 0.2);
  }
  .minus {
    background-color: #ffffff;
  }
`;

export const ModalFooter = styled.div`
  padding: 15px 30px;
  box-shadow: 0 -2px 24px 0 rgba(0, 0, 0, 0.19);
  .apply {
    width: 150px;
    height: 35px;
    border-radius: 6px;
    background-color: ${Theme.primaryColor};
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.base};
    font-weight: 600;
    letter-spacing: -0.07px;
    text-align: center;
    color: ${Theme.white};
  }
`;
export const Banner = styled.div`
  width: 140px;
  height: 90px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  border: ${(props) =>
    props.isSelected
      ? `solid 1px ${Theme.primaryColor}`
      : props.invalid
      ? `solid 1px rgba(255, 0, 0, 0.60)`
      : `solid 1px rgba(26, 65, 106, 0.12)`};
  :last-child {
    margin-right: 0;
  }
  img {
    width: 120px;
    height: 70px;
    object-fit: contain;
  }
`;
