import React, { useState } from "react";
import {
  ListRow,
  IsBlocked,
  UserListingDetail,
  Interest
} from "./UserRow.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import swal from "sweetalert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "store/User/actions";

function UserRow(props) {
  const data = props.data;
  const [expanded, setExpanded] = useState(false);
  const [blockLoader, setBlockLoader] = useState(false);
  const [isBlocked, setIsBlocked] = useState(data.isBlocked);
  const countryName = props.CountryList.find(
    (country) => data.countryCode === country.value
  );

  function changeUserBlockStatus(event, id, blocked) {
    event.stopPropagation();
    setBlockLoader(true);
    props.updateUserBlockStatusRequest(id, !blocked).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        setBlockLoader(false);
        setIsBlocked(!isBlocked);
      }
    });
  }

  return (
    <div>
      <ListRow
        expanded={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <TitleContainer user>
          <img
            alt="user"
            src={
              data.profilePicture
                ? data.profilePicture.mediaUrl
                : require("static/images/profile-pic.jpg")
            }
            className="image"
          />
          <div>
            <div>
              {data.firstName ? data.firstName + " " + data.lastName : "NA"}
            </div>
            <div className="subtext">{data.email}</div>
          </div>
        </TitleContainer>
        <div style={{ width: "20%" }}>
          {data.diallingCode
            ? data.diallingCode + " " + data.phoneNumber
            : "NA"}
        </div>
        <div style={{ width: "14%" }}>
          {countryName ? countryName.label : "Global"}
        </div>
        <div style={{ width: "14%" }}>{data.language}</div>
        <IsBlocked blockLoader blocked={isBlocked} expanded={expanded}>
          <Flexbox
            className="pointer"
            onClick={(e) => changeUserBlockStatus(e, data.id, isBlocked)}
          >
            <img
              alt="isBlocked"
              src={
                isBlocked
                  ? require("static/images/blocked.svg")
                  : require("static/images/not-blocked.svg")
              }
              className="block"
            />
            <span>{isBlocked ? "Blocked" : "Block User"}</span>
            {blockLoader ? (
              <img
                alt="loader"
                src={require("static/images/loader-block-user.svg")}
                className="loader fade"
              />
            ) : null}
          </Flexbox>
          <img
            alt="arrow"
            className="arrow"
            src={require("static/images/dropdown-arrow.svg")}
          />
        </IsBlocked>
      </ListRow>
      <UserListingDetail expanded={expanded}>
        Interests
        <Flexbox margin="16px 0 0 0" flexWrap="wrap">
          {data.interests.map((interest, index) => {
            return (
              <Interest key={index}>
                <img
                  alt={interest.name}
                  src={interest.icon && interest.icon.mediaUrl}
                />
                <div>{interest.name}</div>
              </Interest>
            );
          })}
        </Flexbox>
      </UserListingDetail>
    </div>
  );
}
export default connect(
  ({ User, Country }) => ({ ...User, ...Country }),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(UserRow);
