const setUserList = (data) => ({
  type: "SET_USER_LIST",
  data
});

const setUserPage = (data) => ({
  type: "SET_USER_PAGE",
  data
});
export const mutation = {
  setUserList,
  setUserPage
};
