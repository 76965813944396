import React, { useState, useEffect } from "react";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import { exploreHeads } from "utility/exploreUtility";
import ExploreRow from "components/ExploreRow";
import { Banner } from "static/coreStyles/global.styled";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getScope } from "utility/helperFunctions";
import * as exploreActions from "store/Explore/actions";
import * as categoryActions from "store/Category/actions";
import * as applicationActions from "store/Application/actions";
import * as uploadActions from "store/FileUpload/actions";
import swal from "sweetalert";
import Loader from "components/common/Loader";

function Explore(props) {
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    props.getExploreListRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });

    props.getFullApplicationListRequest();

    props.getCategoryListRequest();
    // eslint-disable-next-line
  }, []);

  let ExploreList = props.ExploreList;

  function UploadImage(e) {
    setImageLoading(true);
    let payload = {
      scope: getScope(true)
    };
    let file = e.target.files[0];
    props.getUploadURLRequest(file).then((res) => {
      if (res.error) {
        setImageLoading(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        payload.illustration = {
          mediaUrl: res.result.getUrl,
          mediaType: file.type
        };
        props.updateExploreRequest(payload).then((res) => {
          if (res.error) {
            setImageLoading(false);
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res.result) {
            props.getExploreListRequest().then(() => setImageLoading(false));
          }
        });
      }
    });
  }

  // [0, 1].map((index) =>
  //   console.log(
  //     "explore list",
  //     index,
  //     ExploreList.topCategories &&
  //       ExploreList.topCategories.length > index &&
  //       ExploreList.topCategories[index].id
  //   )
  // );

  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Explore
          <span>
            {ExploreList &&
              ExploreList.topCategories &&
              ExploreList.topCategories.length > 0 &&
              ` (${ExploreList.topCategories.length + 3})`}
          </span>
        </h1>
      </Flexbox>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Banner
            show={
              ExploreList &&
              ExploreList.illustration &&
              ExploreList.illustration.mediaUrl
            }
          >
            {imageLoading ? (
              <Loader />
            ) : (
              <>
                <div className="banner-placeholder">
                  <img
                    className="banner-placeholder-image"
                    src={require("static/images/banner-placeholder.svg")}
                    alt="upload"
                  />
                  <div>
                    <input
                      type="file"
                      name="file"
                      onChange={UploadImage}
                      className="custom-file-upload"
                    />
                    <label htmlFor="file" className="banner-upload">
                      Upload
                    </label>{" "}
                    banner illustration here
                  </div>
                </div>

                <img
                  alt="banner"
                  src={
                    ExploreList &&
                    ExploreList.illustration &&
                    ExploreList.illustration.mediaUrl
                  }
                  className="uploaded-banner"
                />
              </>
            )}
            <button className="banner-edit-button pointer">
              <img
                className="banner-edit-icon"
                src={require("static/images/edit.svg")}
                alt="edit"
              />
              <input
                type="file"
                onChange={UploadImage}
                name="file"
                id="file"
                className="none"
              />
              <label htmlFor="file" className="pointer">
                EDIT
              </label>
            </button>
          </Banner>

          <div>
            <ExploreRow
              name={exploreHeads[0]}
              banner
              apps={
                ExploreList.banners &&
                ExploreList.banners.map((banner) => {
                  let app = ExploreList.apps[banner.appId];
                  return {
                    app: app,
                    name: app.name,
                    icon:
                      app.images.banners &&
                      app.images.banners.length &&
                      app.images.banners.find((bannerItem) => {
                        return banner.bannerId === bannerItem.id;
                      })
                  };
                })
              }
            ></ExploreRow>
            <ExploreRow
              name={exploreHeads[1]}
              apps={
                ExploreList.featuredApps &&
                ExploreList.featuredApps.map((app) => ExploreList.apps[app])
              }
            ></ExploreRow>
            {/* {[0, 1].map((index) => {
              return (
                <ExploreRow
                  apps={
                    ExploreList.topCategories &&
                    ExploreList.topCategories.length > index &&
                    ExploreList.topCategories[index].topApps &&
                    ExploreList.topCategories[index].topApps.map(
                      (app) => ExploreList.apps[app]
                    )
                  }
                  index={index}
                  key={index}
                  top
                  category={
                    ExploreList.topCategories &&
                    ExploreList.topCategories.length > index &&
                    ExploreList.topCategories[index].id
                  }
                  isEmpty={
                    ExploreList.topCategories &&
                    ExploreList.topCategories.length <= index
                  }
                />
              );
            })} */}
            <ExploreRow
              name={exploreHeads[2]}
              apps={
                ExploreList.tryNew &&
                ExploreList.tryNew.map((app) => ExploreList.apps[app])
              }
            ></ExploreRow>
            <ExploreRow
              name={exploreHeads[3]}
              apps={
                ExploreList.peopleAreLoving &&
                ExploreList.peopleAreLoving.map((app) => ExploreList.apps[app])
              }
            ></ExploreRow>
          </div>
        </>
      )}
    </Container>
  );
}

export default connect(
  ({ Explore, Category, Application }) => ({
    ...Explore,
    ...Category,
    ...Application
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...exploreActions,
        ...categoryActions,
        ...uploadActions,
        ...applicationActions
      },
      dispatch
    )
)(Explore);
