import React, { useState, useEffect } from "react";
import { UserListingHeader } from "views/Users/Users.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import { CommonButton } from "static/coreStyles/global.styled";
import AdvertisementRow from "components/AdvertisementRow";
import AdvertisementDrawer from "components/DrawerModules/AdvertisementDrawer";
import NoResults from "components/common/NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as advertisementActions from "store/Advertisement/actions";
import Loader from "components/common/Loader";
import PaginationLoader from "components/common/PaginationLoader";
import swal from "sweetalert";

function Advertisements(props) {
  const [loading, setLoading] = useState(true);
  const [showAdDrawer, setShowAdDrawer] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      props.AdvertisementPage &&
      props.AdvertisementPage.next &&
      !pageLoader &&
      !loading
    ) {
      setPageLoader(true);
      props
        .getAdvertisementListRequest(props.AdvertisementPage.next)
        .then((res) => {
          if (res.error) {
            setPageLoader(false);
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res) {
            setPageLoader(false);
          }
        });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    props.AdvertisementList &&
      props.AdvertisementList.length &&
      setLoading(false);
    props.AdvertisementList &&
      !props.AdvertisementList.length &&
      props.getAdvertisementListRequest().then((res) => {
        if (res) {
          setLoading(false);
        }
      });
    //eslint-disable-next-line
  }, []);
  let AdvertisementList =
    props.SearchQuery && props.SearchQuery.length > 2
      ? props.SearchList
      : props.AdvertisementList;
  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Advertisements{" "}
          <span>
            {AdvertisementList &&
              AdvertisementList.length > 0 &&
              `(` + AdvertisementList.length + `)`}
          </span>
        </h1>
        <Flexbox>
          <CommonButton
            app
            onClick={() => {
              setShowAdDrawer(true);
            }}
          >
            Add Advertisement
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <UserListingHeader>
        <div style={{ width: "45%" }}>Advertisements</div>
        <div style={{ width: "25%" }}>Country</div>
        <div style={{ width: "24%" }}></div>
      </UserListingHeader>
      {loading ? (
        <Loader />
      ) : AdvertisementList.length ? (
        AdvertisementList.map((data, index) => {
          return <AdvertisementRow data={data} key={index}></AdvertisementRow>;
        })
      ) : (
        <NoResults
          title="advertisements"
          image={require("static/images/empty-advertisements.svg")}
        />
      )}
      {showAdDrawer && (
        <AdvertisementDrawer
          show={showAdDrawer}
          closeDrawer={() => {
            setShowAdDrawer(false);
          }}
        />
      )}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
}
export default connect(
  ({ Advertisement, Search }) => ({ ...Advertisement, ...Search }),
  (dispatch) => bindActionCreators({ ...advertisementActions }, dispatch)
)(Advertisements);
