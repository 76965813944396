import { Advertisement } from "services/Advertisement";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";
import { store } from "store";

const handleError = (err) => {
  console.log("Error in Advertisement action : ", err);
};

// Actions
export const getAdvertisementListRequest = (page) => async (dispatch) => {
  try {
    let prevList = page && store.getState().Advertisement.AdvertisementList;
    page = page ? page.split("api/v1/")[1] : false;
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Advertisement.getAdvertisementList(country, page);
    if (data.result) {
      dispatch(
        mutation.setAdvertisementPage({
          count: data.count,
          totalCount: data.totalCount,
          next: data.next,
          prev: data.prev
        })
      );
      dispatch(
        mutation.setAdvertisementList(
          prevList ? prevList.concat(data.result) : data.result
        )
      );
    }

    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createAdvertisementRequest = (payload) => async (dispatch) => {
  try {
    let data = await Advertisement.createAdvertisement(payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateAdvertisementByIdRequest = (id, payload) => async (
  dispatch
) => {
  try {
    let data = await Advertisement.updateAdvertisementById(id, payload);
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const deleteAdvertisementByIdRequest = (id) => async (dispatch) => {
  try {
    return await Advertisement.deleteAdvertisementById(id);
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const changeAdvertisementsActiveStatusRequest = (id, active) => async (
  dispatch
) => {
  try {
    return await Advertisement.changeAdvertisementsActiveStatus(id, active);
  } catch (err) {
    handleError(err);
    return err;
  }
};
