import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "views/Login";
import Users from "views/Users";
import Applications from "views/Applications";
import Categories from "views/Categories";
import Explore from "views/Explore";
import CategoryDetail from "views/CategoryDetail";
import Dashboard from "views/Dashboard";
import Advertisements from "views/Advertisements";
import Sections from "views/Sections";
import Flavor from "views/Flavor";

function Container() {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/applications" component={Applications} />
      <Route exact path="/categories" component={Categories} />
      <Route exact path="/sections" component={Sections} />
      <Route exact path="/advertisements" component={Advertisements} />
      <Route exact path="/explore" component={Explore} />
      <Route exact path="/sections" component={Sections} />
      <Route exact path="/categories/:id" component={CategoryDetail} />
      <Route exact path="/flavor" component={Flavor} />
    </Switch>
  );
}
export default withRouter(Container);
