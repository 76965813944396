import styled from "styled-components";
import { Theme, FontFamily, Size } from "./theme.styled";

export const Flexbox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-flow: ${(props) => props.flexFlow};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  padding-right: ${(props) => props.paddingRight};
  height: ${(props) => props.height};
  flex-wrap: ${(props) => props.flexWrap};
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  width: ${(props) => props.width};
  :last-child {
    margin: ${(props) => props.marginLast};
  }
`;

export const Container = styled.div`
  padding: 15px 40px 30px;
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #222222;
    span {
      font-family: ${FontFamily.Medium};
      font-size: ${Size.base};
      font-weight: 500;
      letter-spacing: -0.07px;
    }
  }
  .filter-icon {
    height: 14px;
    width: 13px;
  }
  .filter-button {
    height: 40px;
    padding: 11px 13px;
    border-radius: 6px;
    border: solid 1px ${Theme.primaryColor};
    cursor: pointer;
    margin-right: 12px;
  }
  .filters {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    height: 24px;
    padding: 8px;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    border-radius: 6px;
    color: ${Theme.primaryColor};
    background: rgba(11, 95, 227, 0.1);
  }
`;
