import styled from "styled-components";
import { FontFamily } from "static/coreStyles/theme.styled";
export const DragInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: solid 1px
    ${(props) =>
      props.invalid
        ? "rgba(255, 0, 0, 0.60)"
        : "rgba(26, 65, 106, 0.12)"} !important;
  margin-top: 12px;
  display: ${(props) => props.multi && "none"};
  .toggle-visibility {
    visibility: ${(props) => props.show && "hidden"};
  }
  .upload-text {
    font-family: ${FontFamily.SemiBold};
    font-size: 13px;
    color: #222222;
    margin-top: 10px;
    position: relative;
  }
  .upload-subtext {
    opacity: 0.4;
    font-family: ${FontFamily.Medium};
    font-size: 10px;
    color: #212121;
    margin-top: 4px;
  }
  .custom-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .uploaded-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    visibility: ${(props) => !props.show && "hidden"};
  }
  .cross {
    visibility: ${(props) => !props.show && "hidden"};
    position: absolute;
    top: -7px;
    right: -7px;
    width: 17px;
    height: 17px;
  }
  .drag-upload {
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export const CarouselDiv = styled.div`
  position: relative;
  margin-top: 12px;
  .carousel-cross {
    display: ${(props) => !props.show && "none"};
    position: absolute;
    top: -7px;
    right: -7px;
    width: 17px;
    height: 17px;
  }
`;
