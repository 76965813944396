import { Api } from "./config/request";

export const getFlavorList = () => {
  return Api.postRequest(`flavour/data/`, {}); // Post Empty Payload to get flavor list
};

export const createFlavor = (payload) => {
  return Api.postRequest(`flavour/`, payload);
};

export const updateFlavorById = (id, payload) => {
  return Api.putRequest(`flavour/${id}`, payload);
};

export const Flavor = {
  getFlavorList,
  createFlavor,
  updateFlavorById
};
