import styled from "styled-components";
import { Theme, FontFamily, Size } from "static/coreStyles/theme.styled";

export const DrawerContent = styled.div`
  width: ${props => props.width};
  height: 100%;
  background-color: ${Theme.white};
  position: relative;
`;

export const DrawerHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  border-bottom: 4px solid #f7f8f8;
  font-family: ${FontFamily.Bold};
  font-size: ${Size.xl};
  font-weight: bold;
  color: #222222;
  letter-spacing: -0.11px;
`;

export const DrawerFooter = styled.div`
  padding: 30px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
`;
