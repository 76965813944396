const setApplicationList = (data) => ({
  type: "SET_APPLICATION_LIST",
  data
});

const setApplicationPage = (data) => ({
  type: "SET_APPLICATION_PAGE",
  data
});

export const mutation = {
  setApplicationList,
  setApplicationPage
};
