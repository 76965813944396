const setCategoryList = (data) => ({ type: "SET_CATEGORY_LIST", data });
const setCategoryDetail = (data) => ({ type: "SET_CATEGORY_DETAIL", data });
const setCategoryPage = (data) => ({ type: "SET_CATEGORY_PAGE", data });
const setCategoryListOfAllFlavors = (data) => ({
  type: "SET_CATEGORY_LIST_OF_ALL_FLAVORS",
  data
});

export const mutation = {
  setCategoryList,
  setCategoryDetail,
  setCategoryPage,
  setCategoryListOfAllFlavors
};
