import React, { useState, useEffect } from "react";
import { UserListingHeader } from "./Users.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import { CommonButton } from "static/coreStyles/global.styled";
import UserRow from "components/UserRow";
//import FilterModal from "components/FilterModal";
import NoResults from "components/common/NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "store/User/actions";
import Loader from "components/common/Loader";
import swal from "sweetalert";
import PaginationLoader from "components/common/PaginationLoader";

function Users(props) {
  // const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [csvLoader, setCsvLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      props.UserPage &&
      props.UserPage.next &&
      !pageLoader &&
      !loading
    ) {
      setPageLoader(true);
      props.getUserListRequest(props.UserPage.next).then((res) => {
        if (res.error) {
          setPageLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res) {
          setPageLoader(false);
        }
      });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    props.getUserListRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  function downloadCsv() {
    setCsvLoader(true);
    props.exportUsersRequest().then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        setCsvLoader(false);
        window.open(res.result);
      }
    });
  }

  let UserList =
    props.SearchQuery &&
    props.SearchQuery.length > 2 &&
    props.SearchComplete === true
      ? props.SearchList
      : props.UserList;
  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Users{" "}
          <span>{UserList.length > 0 && `(` + UserList.length + `)`}</span>
        </h1>
        <Flexbox>
          {/* <div
            className="filter-button"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <img
              src={require("static/images/menu.svg")}
              alt="filter"
              className="filter-icon"
            />
          </div> */}
          <CommonButton onClick={() => downloadCsv()} width="160px">
            {csvLoader ? (
              <img
                alt="loader"
                src={require("static/images/loader-block-user.svg")}
                className="loader fade"
              />
            ) : (
              "Export as CSV"
            )}
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <UserListingHeader>
        <div style={{ width: "32%" }}>User</div>
        <div style={{ width: "20%" }}>Phone Number</div>
        <div style={{ width: "14%" }}>Country</div>
        <div style={{ width: "14%" }}>Languages</div>
        <div style={{ width: "20%" }}></div>
      </UserListingHeader>
      <div>
        {loading ? (
          <Loader />
        ) : UserList && UserList.length > 0 ? (
          UserList.map((data, index) => {
            return <UserRow data={data} key={index}></UserRow>;
          })
        ) : (
          <NoResults
            title="users"
            image={require("static/images/empty-users.svg")}
          />
        )}
      </div>

      {/* {showFilter && (
        <FilterModal
          show={showFilter}
          closeModal={() => {
            setShowFilter(false);
          }}
        />
      )} */}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
}
export default connect(
  ({ User, Search }) => ({ ...User, ...Search }),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(Users);
