import { FileUpload } from "services/FileUpload";
import { mutation } from "./mutations";
import axios from "axios";

const handleError = err => {
  console.log("Error in FileUpload action : ", err);
};

// Actions
export const getUploadURLRequest = blobFile => async dispatch => {
  try {
    let name = blobFile.name;
    let data = await FileUpload.getUploadURL(name);
    if (data.result) {
      dispatch(mutation.setFileUploadURL(data.result.getUrl));
      await axios.put(data.result.putUrl, blobFile, {
        headers: {
          "Content-Type": blobFile.type
        }
      });

      return data;
    } else {
      return data;
    }
  } catch (err) {
    handleError(err);
    return err;
  }
};
