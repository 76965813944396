import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const ListRow = styled.div.attrs((props) => ({
  style: {
    borderBottomLeftRadius: props.expanded && "0",
    borderBottomRightRadius: props.expanded && "0"
  }
}))`
  width: 100%;
  background: ${Theme.white};
  border-radius: 6px;
  margin-top: 8px;
  padding: 16px;
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.base};
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
  position: relative;
  .delete-button {
    margin-left: 20px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const IsBlocked = styled.div.attrs((props) => ({
  style: {
    color: props.blocked ? "#f73a33" : "#626d79"
  }
}))`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-between;
  font-size: ${Size.xs};
  .block {
    margin-right: 5px;
  }
  .arrow {
    height: 8px;
    width: 20px;
    transform: ${(props) =>
      props.expanded ? `rotate(180deg)` : `rotate(0deg)`};
    cursor: pointer;
  }
  .fade {
    animation: fadeIn 0.3s linear;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      ,
      100% {
        opacity: 1;
      }
    }
  }
  .loader {
    padding: 2px;
    height: 15px;
    width: 15px;
  }
`;
export const UserListingDetail = styled.div.attrs((props) => ({
  style: {
    display: props.expanded ? "block" : "none"
  }
}))`
  border-top: 2px solid #f7f8f8;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 24px 16px;
  background: ${Theme.white};
  color: rgba(99, 105, 112, 0.7);
  font-family: ${FontFamily.Medium};
  font-size: ${Size.xs};
  font-weight: 500;
`;
export const Interest = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: ${Theme.primaryColor};
  img {
    height: 15px;
    margin-right: 10px;
  }
  color: ${Theme.white};
  margin-right: 10px;
  :last-child {
    margin-right: 0;
  }
  text-align: center;
  text-transform: capitalize;
`;
