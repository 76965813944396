import { initialState } from "./state";

export const Country = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COUNTRY_LIST": {
      return Object.assign({}, state, {
        CountryList: action.data
      });
    }
    default:
      return state;
  }
};
