import styled from "styled-components";
import { FontFamily, Size } from "static/coreStyles/theme.styled";

export const AppGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .app-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .app-name {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    color: #636970;
    margin-top: 9px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
    overflow: hidden;
    padding: 5px;
    width: 38px;
    height: 38px;
    background-color: #f5f5f5;
    border-radius: 6px;
  }
`;
