import styled from "styled-components";
import { Theme } from "static/coreStyles/theme.styled";

export const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px 20px 0;
  position: relative;
  :last-child {
    margin-right: 0;
  }

  .visible {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0;
    top: -8px;
    visibility: hidden;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Theme.primaryColor};
    border-radius: 50%;
    border: solid white 2px;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0;
    top: -8px;
    visibility: hidden;
  }
  .loader {
    height: 14px;
    width: 14px;
    visibility: hidden;
  }
  :hover {
    .visible {
      visibility: visible;
    }
    .loader-container {
      visibility: visible;
    }
    .loader {
      visibility: visible;
    }
  }
  .fade {
    animation: fadeIn 0.3s linear;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      ,
      100% {
        opacity: 1;
      }
    }
  }
`;
