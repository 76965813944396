import { Api } from "./config/request";

export const searchList = (search, list) => {
  return list.filter((element) =>
    element.name.toLowerCase().includes(search.toLowerCase())
  );
};

export const getAdvertisementSearchList = (search, country) => {
  return Api.getRequest(
    `advertisements/?search=${search}${
      country === "Global" ? "" : `&countryCode=${country}`
    }`
  );
};

export const getApplicationSearchList = (search, country) => {
  return Api.getRequest(
    `apps/?search=${search}${
      country === "Global"
        ? "&isGlobal=true"
        : country === "All"
        ? ""
        : `&countryCode=${country}`
    }`
  );
};

export const getUserSearchList = (search, country, phoneNumber) => {
  return Api.getRequest(
    `users/?${phoneNumber ? "phoneNumber" : "search"}=${search}${
      country === "Global"
        ? "&isGlobal=true"
        : country === "All"
        ? ""
        : `&countryCode=${country}`
    }`
  );
};

export const Search = {
  searchList,
  getAdvertisementSearchList,
  getApplicationSearchList,
  getUserSearchList
};
