import React, { useState } from "react";
import { ModalBody, ModalFooter, Search, Filters } from "./FilterModal.styled";
import Modal from "components/common/Modal";
import { connect } from "react-redux";

function FilterModal(props) {
  const [selectedCategory, setSelectedCategory] = useState(
    props.filters && Object.keys(props.filters).length ? props.filters : {}
  );
  function changeSelectedCategory(e) {
    let category = JSON.parse(e.target.value);
    let temp = Object.assign({}, selectedCategory);
    if (selectedCategory[category.id]) {
      delete temp[category.id];
    } else {
      temp[category.id] = { name: category.name, id: category.id };
    }
    setSelectedCategory(temp);
  }

  function close() {
    props.getFliters(selectedCategory);
    props.closeModal();
  }

  return (
    <Modal
      show={props.show}
      title={"Apply Filter"}
      closeModal={props.closeModal}
    >
      <>
        <ModalBody>
          <Search placeholder="Search for any interest  " />
          <img
            alt="search"
            src={require("static/images/search.svg")}
            className="search"
          />
          <Filters>
            {props.CategoryList.map((category, index) => {
              let list = selectedCategory && Object.keys(selectedCategory);
              return (
                <label key={index} className="container">
                  {category.name}
                  <input
                    type="checkbox"
                    checked={list.length && list.includes(category.id)}
                    value={JSON.stringify(category)}
                    onChange={changeSelectedCategory}
                  />
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </Filters>
        </ModalBody>
        <ModalFooter>
          <button className="apply" onClick={close}>
            Apply
          </button>
        </ModalFooter>
      </>
    </Modal>
  );
}
export default connect(({ Category }) => ({
  ...Category
}))(FilterModal);
