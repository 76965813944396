import { initialState } from "./state";

export const Flavor = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FLAVOR_LIST": {
      return Object.assign({}, state, {
        FlavorList: action.data
      });
    }
    // case "SET_CATEGORY_DETAIL": {
    //   return Object.assign({}, state, {
    //     CategoryDetail: action.data
    //   });
    // }
    // case "SET_CATEGORY_PAGE": {
    //   return Object.assign({}, state, {
    //     CategoryPage: action.data
    //   });
    // }
    default:
      return state;
  }
};
