import styled from "styled-components";
export const SubContainer = styled.div`
  display: flex;

  .button-holder {
    margin: 12px 0 0 20px;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: solid 1px rgba(26, 65, 106, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plus {
    background-color: rgba(160, 181, 205, 0.2);
  }
  .minus {
    background-color: #ffffff;
  }
`;
