import React from "react";
import { AppGroupContainer } from "./AppGroup.styled";
import { connect } from "react-redux";

function AppGroup(props) {
  const size = 4;
  return (
    <AppGroupContainer>
      <div className="icon-container">
        {props.ids &&
          props.ids.length > 0 &&
          props.ids.slice(0, size).map((app, index) => {
            app = props.Section.apps[app];
            return (
              <img
                alt="app"
                src={app && app.icon && app.icon.mediaUrl}
                className="app-icon"
                key={index}
              />
            );
          })}
      </div>
      <div className="app-name">{props.name}</div>
    </AppGroupContainer>
  );
}
export default connect(({ Section }) => ({ ...Section }))(AppGroup);
