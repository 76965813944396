import { storage as LocalStorage } from "services/config/storage";

export const platformsList = [
  {
    value: "web",
    label: "Web"
  },
  {
    value: "android",
    label: "Android"
  },
  {
    value: "ios",
    label: "IOS"
  }
];

export const getSelectedFlavorData = () => {
  const LocalStorageFetchedData = LocalStorage.fetch.flavorData();
  const flavorMongoID = LocalStorageFetchedData._id;
  const applicationID = LocalStorageFetchedData.value;
  const flavorName = LocalStorageFetchedData.label;
  const splitApplicationID = applicationID.split("_");
  const platform = splitApplicationID[0];

  return {
    applicationID,
    flavorName,
    platform,
    flavorMongoID
  };
};
