import React, { useState } from "react";
import Drawer from "components/common/Drawer";
import { DrawerBody } from "static/coreStyles/global.styled";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import { getScope } from "utility/helperFunctions";
import swal from "sweetalert";
import * as applicationActions from "store/Application/actions";

function CategoryApplicationsDrawer(props) {
  const [selectedApps, setSelectedApps] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [loader, setLoader] = useState(false);

  const applications = Object.keys(
    props.CategoryDetail && props.CategoryDetail.allApps
  ).map((option) => {
    return {
      value: option,
      label: props.CategoryDetail.allApps[option].name
    };
  });
  // .filter(
  //   (option) =>
  //     props.CategoryDetail.allApps[option].subCategoryIds.indexOf(
  //       props.subCategory.id
  //     ) > -1
  // )

  let defaultApps =
    props.subCategory &&
    props.subCategory.apps &&
    props.subCategory.apps.map((app) => {
      return { value: app, label: props.CategoryDetail.allApps[app].name };
    });

  function validateForm() {
    if (
      (selectedApps &&
        selectedApps.length < 1 &&
        defaultApps &&
        defaultApps.length < 1) ||
      !selectedApps
    ) {
      setInvalid(true);
      return false;
    }
    return true;
  }

  function submitForm() {
    if (validateForm()) {
      setLoader(true);
      let payload = {
        scope: getScope(true),
        sectionType: 3,
        apps:
          selectedApps && selectedApps.length
            ? selectedApps.map((app) => app.value)
            : defaultApps
            ? defaultApps.map((app) => app.value)
            : [],
        subCategoryId: props.subCategory.id
      };
      props
        .updateCategoryDetailRequest(props.categoryId, payload)
        .then((res) => {
          if (res.error) {
            setLoader(false);
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else {
            setLoader(false);
            props.getCategoryDetailRequest(props.categoryId);
            props.getCategoryListRequest();
            props.closeDrawer();
          }
        });
    }
  }
  return (
    <Drawer
      title={props.subCategory.name}
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText="Save"
      loader={loader}
      onSave={submitForm}
    >
      <DrawerBody>
        <DropdownInputContainer
          title="Select Applications"
          defaultValue={defaultApps}
          options={applications}
          passSelectedValues={(values) => {
            defaultApps = [];
            setSelectedApps(values);
          }}
          multi
          edit
          invalid={invalid && !selectedApps}
        />

        {/* <DropdownInputContainer
          title="Applications"
          placeholder="Select the Applicatio"
          passSelectedValues={(value) => setCategory(value)}
          options={categoryOptions}
          disabled={false}
          // disabled={props.edit || props.categoryDetailScreen}
          edit={props.edit}
          noAlter
          default={props.categoryDetailScreen}
          defaultValue={
            props.categoryDetailScreen
              ? props.categoryDetailScreen
              : props.category
          }
          invalid={invalid && !category}
          multi
        /> */}
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Category, Application }) => ({ ...Category, ...Application }),
  (dispatch) =>
    bindActionCreators({ ...categoryActions, ...applicationActions }, dispatch)
)(CategoryApplicationsDrawer);
