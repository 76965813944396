import React, { useState, useEffect } from "react";
import { CommonButton } from "static/coreStyles/global.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import FlavorDrawer from "components/DrawerModules/FlavorDrawer";
import NoResults from "components/common/NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as flavorActions from "store/Flavor/actions";
import Loader from "components/common/Loader";
import PaginationLoader from "components/common/PaginationLoader";
import FlavorRow from "components/FlavorRow";
import { UserListingHeader } from "views/Users/Users.styled";

const Flavor = (props) => {
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    props.getFlavorList().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  let FlavorList =
    props.SearchQuery &&
    props.SearchQuery.length > 2 &&
    props.SearchComplete === true
      ? props.SearchList
      : props.FlavorList;

  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Flavors{" "}
          <span>
            {FlavorList &&
              FlavorList.length > 0 &&
              `(` + FlavorList.length + `)`}
          </span>
        </h1>
        <Flexbox>
          <CommonButton
            app
            onClick={() => {
              setShowCategoryDrawer(true);
            }}
          >
            Add Flavor
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <UserListingHeader>
        <div style={{ width: "35%" }}>Flavor Name</div>
        <div style={{ width: "20%" }}>Package ID</div>
        <div style={{ width: "25%" }}>Platform</div>
        <div style={{ width: "0%" }}></div>
      </UserListingHeader>
      <>
        {loading ? (
          <Loader />
        ) : FlavorList && FlavorList.length > 0 ? (
          FlavorList.map((category, index) => {
            return <FlavorRow data={category} key={index}></FlavorRow>;
          })
        ) : (
          <NoResults
            title="flavors"
            image={require("static/images/empty-categories.svg")}
          />
        )}
      </>
      {showCategoryDrawer && (
        <FlavorDrawer
          show={showCategoryDrawer}
          closeDrawer={() => {
            setShowCategoryDrawer(false);
          }}
        />
      )}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
};

export default connect(
  ({ Flavor, Search }) => ({ ...Flavor, ...Search }),
  (dispatch) => bindActionCreators({ ...flavorActions }, dispatch)
)(Flavor);
