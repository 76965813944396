import styled from "styled-components";
import { Theme, FontFamily, Size } from "static/coreStyles/theme.styled";

export const Parent = styled.div`
  padding: 15px 40px 30px;
`;
export const Container = styled.div`
  border-radius: 6px;
  background-color: ${Theme.white};
  padding: 20px;
  .back {
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.xs};
    font-weight: 600;
    color: #626d79;
    margin: 0 0 0 6px;
  }
  .category-img {
    position: absolute;
    left: 30px;
    bottom: -18px;
    z-index: 1;
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
  .category-name {
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.xl};
    font-weight: 600;
    color: #222222;
    position: absolute;
    left: 105px;
    bottom: 8px;
    z-index: 1;
  }
  .top-apps {
    margin: 0 0 0 9px;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    font-weight: 500;
    line-height: 1.07;
    color: #636970;
  }
  .bar {
    width: 2px;
    height: 13px;
    border-radius: 1px;
    background-color: #d52a56;
  }
  .app-img {
    object-fit: contain;
    width: 55px;
    height: 55px;
    border-radius: 8px;
  }
  .app-name {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    font-weight: 500;
    text-align: center;
    color: #636970;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .app-edge {
    color: #0b5fe3;
  }
  .add-new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    background-color: rgba(160, 181, 205, 0.2);
    border-radius: 8px;
  }
  .add-new-circle {
    width: 19px;
    height: 19px;
  }
  .reorder {
    display: flex;
    flex-wrap: wrap;
  }
  .sortable {
    :hover {
      cursor: pointer;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.07);
    }
  }
`;
export const ApplicationSubContainer = styled.div`
  overflow: hidden;
  width: 100%;
  border-radius: 6px;
  max-height: ${(props) => (props.expanded ? "auto" : "170px")};
  padding: ${(props) => (props.section ? "0" : "20px 16px 0")};
  border: ${(props) =>
    props.section ? "" : "solid 1px rgba(11, 95, 227, 0.14)"};
  margin: ${(props) => props.margin};
  padding-bottom: ${(props) => props.paddingBottom};
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }

  .arrow {
    height: 8px;
    width: 20px;
    transform: ${(props) =>
      props.expanded ? `rotate(180deg)` : `rotate(0deg)`};
    cursor: pointer;
  }
  .app-container {
    position: relative;
    :hover {
      .visible {
        visibility: visible;
      }
    }
  }
  .visible {
    position: absolute;
    right: 0;
    top: -8px;
    visibility: hidden;
  }

  h4 {
    margin: 0 4px 20px 0;
    font-family: ${FontFamily.SemiBold};
    font-weight: 600;
    color: #636970;
  }
`;
