import styled from "styled-components";
import { Theme, Size, FontFamily } from "static/coreStyles/theme.styled";

export const ModalFooter = styled.div`
  padding: 24px;
  border-top: 4px solid #f7f8f8;
  .apply {
    width: 150px;
    height: 35px;
    border-radius: 6px;
    background-color: ${Theme.primaryColor};
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.base};
    font-weight: 600;
    letter-spacing: -0.07px;
    text-align: center;
    color: ${Theme.white};
  }
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 24px;
  .search {
    position: absolute;
    top: 36px;
    left: 36px;
  }
`;

export const Search = styled.input`
  height: 36px;
  width: 100%;
  border-radius: 6px;
  background-color: rgba(211, 211, 211, 0.3);
  padding: 10px 12px 10px 35px;
  font-family: ${FontFamily.Medium};
  font-size: ${Size.sm};
  font-weight: 500;
  ::placeholder {
    font-family: ${FontFamily.Medium};
    font-size: ${Size.sm};
    font-weight: 500;
    color: rgba(99, 105, 112, 0.7);
  }
  margin-bottom: 18px;
`;

export const Filters = styled.div`
  height: 180px;
  overflow-y: auto;
  .container {
    margin-bottom: 13px;
    display: block;
    width: fit-content;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.base};
    font-weight: 500;
    color: #212121;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    :last-child {
      margin-bottom: 0;
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    margin: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: solid 0.8px rgba(145, 153, 161, 0.4);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${Theme.white};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    width: 6px;
    height: 6px;
    border-radius: 2px;
    background: ${Theme.primaryColor};
  }
`;
