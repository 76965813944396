import React, { useState, useEffect } from "react";
import {} from "./AdvertisementRow.styled";
import { ListRow } from "components/UserRow/UserRow.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import Switch from "components/common/Switch";
import { Flexbox } from "static/coreStyles/container.styled";
import { storage as LocalStorage } from "services/config/storage";
import { formatDate } from "utility/helperFunctions";
import AdvertisementDrawer from "components/DrawerModules/AdvertisementDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as advertisementActions from "store/Advertisement/actions";
import swal from "sweetalert";

function AdvertisementRow(props) {
  const data = props.data;

  function editAdvertisement(e) {
    e.stopPropagation();
    setShowAdDrawer(true);
  }
  const [showAdDrawer, setShowAdDrawer] = useState(false);
  const [activeState, setActiveState] = useState(props.data.isActive);
  const [deleteLoader, setDeleteLoader] = useState(false);
  useEffect(() => {
    setActiveState(props.data.isActive);
  }, [props.data.isActive]);
  function changeActiveStatus() {
    props
      .changeAdvertisementsActiveStatusRequest(data.id, !activeState)
      .then((res) => {
        if (res.error) {
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res.result) {
          props.getAdvertisementListRequest();
        }
      });
    setActiveState(!activeState);
  }
  function deleteApplication() {
    setDeleteLoader(true);
    props.deleteAdvertisementByIdRequest(data.id).then((res) => {
      if (!res) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
        setDeleteLoader(false);
      } else if (res) {
        props.getAdvertisementListRequest().then((res) => {
          if (!res) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
            setDeleteLoader(false);
          } else if (res) {
            setDeleteLoader(false);
          }
        });
      }
    });
  }
  function deleteConfirmation() {
    swal({
      title: `Are you sure you want to delete ${data.app.name}?`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        deleteApplication();
        swal(`${data.app.name} has been deleted!`, {
          icon: "success"
        });
      } else {
        swal(`${data.app.name} is safe!`);
      }
    });
  }
  return (
    <div>
      <ListRow>
        <TitleContainer ad>
          <img
            alt="ad"
            src={data.marker && data.marker.mediaUrl}
            className="ad-marker"
          />
          <img
            alt={data.app.name}
            src={data.app.icon && data.app.icon.mediaUrl}
            className="image"
          />
          <div>
            <div>{data.app.name}</div>
            <div className="subtext">{formatDate(data.createdAt)}</div>
          </div>
        </TitleContainer>
        <div style={{ width: "25%" }}>
          {LocalStorage.fetch.countryData()
            ? LocalStorage.fetch.countryData().label
            : "Global"}
        </div>
        <div style={{ width: "24%" }}>
          <Flexbox justifyContent="flex-end">
            <Switch state={activeState} changeStatus={changeActiveStatus} />
            <img
              src={require("static/images/edit.svg")}
              alt="edit"
              onClick={editAdvertisement}
              className="pointer"
            />
            {deleteLoader ? (
              <img
                alt="loader"
                height="16px"
                width="16px"
                src={require("static/images/loader-block-user.svg")}
                className="delete-button fade"
              />
            ) : (
              <img
                src={require("static/images/trash.svg")}
                alt="edit"
                height="16px"
                width="16px"
                className="delete-button pointer"
                onClick={() => deleteConfirmation()}
              />
            )}
          </Flexbox>
        </div>
      </ListRow>
      {showAdDrawer && (
        <AdvertisementDrawer
          edit
          name={data.appId}
          sticker={data.marker.mediaUrl}
          show={showAdDrawer}
          id={data.id}
          closeDrawer={() => {
            setShowAdDrawer(false);
          }}
        />
      )}
    </div>
  );
}
export default connect(
  ({ Advertisement }) => ({ ...Advertisement }),
  (dispatch) => bindActionCreators({ ...advertisementActions }, dispatch)
)(AdvertisementRow);
