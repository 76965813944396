import React, { useState } from "react";
import { RadioToolbar } from "./SectionDrawer.styled";
import Drawer from "components/common/Drawer";
import { DrawerBody, UploadTitle } from "static/coreStyles/global.styled";
import { bindActionCreators } from "redux";
import * as sectionActions from "store/Section/actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getScope } from "utility/helperFunctions";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import InputContainer from "components/common/InputContainer";

function SectionDrawer(props) {
  const [invalid, setInvalid] = useState(false);
  const [application, setapplication] = useState(
    props.type === "application" ? props.defaultValue : []
  );
  const [advertisements, setAdvertisements] = useState(
    props.type === "pool" ? props.defaultValue : []
  );
  const [name, setName] = useState(
    props.type === "group" ? props.defaultValue && props.defaultValue.name : ""
  );
  const [group, setGroup] = useState(
    props.type === "group" ? props.defaultValue : []
  );
  const [type, setType] = useState(props.type);
  const addedAppsList = [];
  props.data &&
    props.data.forEach((appIds) =>
      appIds.ids.forEach((app) => addedAppsList.push(app))
    );
  const ApplicationOptions = props.ApplicationList.reduce(function (
    filtered,
    option
  ) {
    if (option.isActive && !addedAppsList.includes(option.id)) {
      filtered.push({ label: option.name, value: option.id });
    }
    return filtered;
  },
  []);
  const AdvertisementOptions = props.AdvertisementList.reduce(function (
    filtered,
    option
  ) {
    if (option.isActive) {
      filtered.push({ label: option.app.name, value: option.id });
    }
    return filtered;
  },
  []);
  function AddAppsToSection() {
    let list =
      props.Section[props.sectionKey] && props.Section[props.sectionKey].items
        ? props.Section[props.sectionKey].items
        : [];
    let item = {
      type: type === "pool" ? 2 : 1,
      ids:
        type === "pool"
          ? advertisements &&
            advertisements.map((item) => {
              return item.value;
            })
          : type === "group"
          ? group &&
            group.map((item) => {
              return item.value;
            })
          : [application && application.value]
    };
    if (name) {
      item.name = name;
    }
    if (props.edit) {
      list[props.index] = item;
    }
    let payload = {
      type:
        props.sectionKey === "dashboard"
          ? 3
          : props.sectionKey === "appsWeLove"
          ? 1
          : 2,
      scope: getScope(true),
      items: props.edit ? list : list.concat(item)
    };

    props.updateSectionRequest(payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        props.getSectionRequest();
        props.closeDrawer();
      }
    });
  }

  function remove() {
    let list =
      props.Section[props.sectionKey] && props.Section[props.sectionKey].items;
    list = [
      ...list.slice(0, props.index),
      ...list.slice(props.index + 1, list.length)
    ];
    let payload = {
      type:
        props.sectionKey === "dashboard"
          ? 3
          : props.sectionKey === "appsWeLove"
          ? 1
          : 2,
      scope: getScope(true),
      items: list.map(item=>{
        delete item['id']
        delete item['_id']
        return item
      })
    };

    props.updateSectionRequest(payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        props.getSectionRequest();
        props.closeDrawer();
      }
    });
  }

  function validateForm() {
    if (
      (type === "application" && application) ||
      (type === "group" && group && group.length > 1 && name) ||
      (type === "pool" && advertisements)
    ) {
      AddAppsToSection();
    } else {
      setInvalid(true);
    }
  }

  return (
    <Drawer
      title="Add Applications"
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText="Save"
      onSave={validateForm}
      remove={props.edit}
      onRemove={remove}
    >
      <DrawerBody>
        <UploadTitle opacity="1" marginBottom="20px">
          Choose One
        </UploadTitle>
        <RadioToolbar>
          <input
            type="radio"
            id="application"
            name="type"
            value="application"
            checked={type === "application"}
            onChange={(e) => {
              setInvalid(false);
              setType(e.target.value);
            }}
          />
          <label htmlFor="application">Application</label>

          <input
            type="radio"
            id="group"
            name="type"
            value="group"
            checked={type === "group"}
            onChange={(e) => {
              setInvalid(false);
              setType(e.target.value);
            }}
          />
          <label htmlFor="group">Application Group</label>

          <input
            type="radio"
            id="pool"
            name="type"
            value="pool"
            checked={type === "pool"}
            onChange={(e) => {
              setInvalid(false);
              setType(e.target.value);
            }}
          />
          <label htmlFor="pool">Advertisement Pool</label>
        </RadioToolbar>
        {type === "application" ? (
          <DropdownInputContainer
            title="Application"
            placeholder="Select Application"
            options={ApplicationOptions}
            noAlter
            edit={props.edit}
            defaultValue={
              props.type === "application" ? props.defaultValue : undefined
            }
            passSelectedValues={(val) => setapplication(val)}
            invalid={invalid && !application}
          />
        ) : type === "group" ? (
          <>
            <DropdownInputContainer
              placeholder="Create Group"
              title="Application Group"
              multi
              edit={props.edit}
              defaultValue={
                props.type === "group" ? props.defaultValue : undefined
              }
              options={ApplicationOptions}
              passSelectedValues={(val) => {
                val = val ? val : [];
                setGroup(val);
              }}
              group
              invalid={invalid && group && group.length < 2}
            />
            <InputContainer
              title="Name"
              value={props.defaultValue ? props.defaultValue.name : ""}
              placeholder="Enter the group name"
              getInput={(val) => setName(val)}
              invalid={invalid && !name}
            />
          </>
        ) : (
          <DropdownInputContainer
            title="Advertisements Pool"
            placeholder="Create Pool"
            clearable
            edit={props.edit}
            defaultValue={
              props.type === "pool" ? props.defaultValue : undefined
            }
            multi
            options={AdvertisementOptions}
            passSelectedValues={(val) => {
              if (!val) {
                val = [];
              }
              setAdvertisements(val);
            }}
            invalid={invalid && advertisements}
          />
        )}
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Section, Application, Advertisement }) => ({
    ...Section,
    ...Application,
    ...Advertisement
  }),
  (dispatch) => bindActionCreators({ ...sectionActions }, dispatch)
)(SectionDrawer);
