import { initialState } from "./state";

export const User = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_LIST": {
      return Object.assign({}, state, {
        UserList: action.data
      });
    }
    case "SET_USER_PAGE": {
      return Object.assign({}, state, {
        UserPage: action.data
      });
    }
    default:
      return state;
  }
};
