import React, { useState, useRef, useEffect } from "react";
import debounce from "debounce";
import {
  SearchContainer,
  SearchInput,
  UserDropdown,
  customStyles,
  UserDropdownContent
} from "./Header.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import Select, { components } from "react-select";
import { storage as LocalStorage } from "services/config/storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as searchActions from "store/Search/actions";
import * as flavorActions from "store/Flavor/actions";
import { withRouter, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import {  } from "react-router-dom";
// import { getFlavorList } from "store/Flavor/actions";

/**
 * Convert Original Format of Flavor List to Dropdown Format
 * @param {Array} originalFormatArray
 * originalFormatArray = [{
 * applicationId: "web_123456"
 * createdAt: "2021-10-29T13:43:37.682Z"
 * isActive: true
 * name: "ak-testing_2"
 * platform: "web"
 * updatedAt: "2021-10-29T13:43:37.682Z"
 * __v: 0
 * _id: "617bfa89f3094287300a70d2"
 * }, {...}, {...}, {...}]
 *
 * @returns Array of Objects {value: "applicationId", label: "name", _id: "xxxxx"}
 */
const convertToValueLabelFormat = (originalFormatArray) => {
  return originalFormatArray
    ? originalFormatArray.map(({ applicationId, name, _id }) => ({
        value: applicationId,
        label: name,
        _id: _id
      }))
    : [{ value: "error", label: "Error Occurred in Loading Data" }];
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt="dropdown" src={require("static/images/dropdown-arrow.svg")} />
    </components.DropdownIndicator>
  );
};

function Header(props) {
  let history = useHistory();
  const ProfileNode = useRef(null);
  const location = useLocation();
  const [isFlavorDropdownVisible, setIsFlavorDropdownVisible] = useState(true);

  useEffect(() => {
    if (location.pathname === "/applications")
      setIsFlavorDropdownVisible(false);
    else setIsFlavorDropdownVisible(true);
  }, [location]);

  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);

  const [countryOptions, setCountryOptions] = useState([...props.CountryList]);

  const [flavorOptions, setFlavorOptions] = useState(
    convertToValueLabelFormat(
      Array.isArray(props.FlavorList) ? [...props.FlavorList] : []
    )
  );

  useEffect(() => {
    setCountryOptions([{ value: "All", label: "All" }, ...countryOptions]);
    setFlavorOptions([{ value: "All", label: "All" }, ...flavorOptions]);
    document.addEventListener("click", handleClick, false);
    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
    // eslint-disable-next-line
  }, []);

  const updateFlavorOptions = async () => {
    let ans = await props.getFlavorList();
    setFlavorOptions((prev) => [
      { value: "All", label: "All" },
      ...convertToValueLabelFormat(ans.result)
    ]);
  };

  // This populates the dropdown when a flavor is created
  useEffect(() => {
    if (
      flavorOptions.length === 0 ||
      (flavorOptions.length === 1 && flavorOptions[0].value === "All")
    ) {
      updateFlavorOptions();
    }
  }, []);

  function handleClick(e) {
    if (ProfileNode.current && !ProfileNode.current.contains(e.target)) {
      setOpenProfileDropdown(false);
    }
  }

  function searchList(value) {
    let list = [];
    props.setSearchRequest(value);
    if (value && value.length > 2) {
      if (window.location.pathname === "/categories") {
        list = props.CategoryList;
      } else {
        list = false;
      }
      props.searchListRequest(value, list);
    }
  }

  function logout(e) {
    e.stopPropagation();
    LocalStorage.destroy.countryData();
    LocalStorage.destroy.authToken();
    LocalStorage.destroy.persistStore();
    props.history.push("/login");
  }

  function getCountry() {
    if (
      LocalStorage.fetch.countryData() &&
      LocalStorage.fetch.countryData().label === "All"
    ) {
      LocalStorage.set.countryData(props.CountryList[0]);
      return props.CountryList[0];
    } else if (LocalStorage.fetch.countryData()) {
      return LocalStorage.fetch.countryData();
    } else {
      return props.CountryList[0];
    }
  }

  function getFlavor() {
    if (
      LocalStorage.fetch.flavorData() &&
      LocalStorage.fetch.flavorData().label === "All"
    ) {
      LocalStorage.set.flavorData(props.FlavorList[0]);
      return props.FlavorList[0];
    } else if (LocalStorage.fetch.flavorData()) {
      return LocalStorage.fetch.flavorData();
    } else {
      return props.FlavorList[0];
    }
  }

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (word) {
      value = value + target.includes(word);
    });
    return value === 1;
  }

  let showSearchBar = !contains(window.location.pathname, [
    "/sections",
    "/explore",
    "/categories/"
  ]);

  let showAllResults =
    ["/users", "/applications"].indexOf(window.location.pathname) >= 0
      ? true
      : false;

  function changeCountry(value) {
    LocalStorage.set.countryData(value);
    window.location.reload();
  }

  function changeFlavor(value) {
    // setFlavor(value);
    LocalStorage.set.flavorData(value);
    if (location.pathname === "/categories") {
      window.location.reload();
    } else {
      history.push("/categories");
    }
  }

  return (
    <SearchContainer showSearchBar={showSearchBar}>
      <SearchInput
        placeholder="Search"
        value={props.SearchQuery}
        onChange={(e) => {
          debounce(searchList(e.target.value), 300);
        }}
        showSearchBar={showSearchBar}
      />
      <img
        alt="search"
        src={require("static/images/search.svg")}
        className="search-icon"
      />
      {props.SearchQuery &&
        props.SearchQuery.length > 2 &&
        props.SearchComplete === false && (
          <img
            alt="loader"
            src={require("static/images/loader-block-user.svg")}
            className="loader fade"
          />
        )}
      <Flexbox>
        {isFlavorDropdownVisible && (
          <Select
            components={{ DropdownIndicator }}
            value={
              LocalStorage.fetch.flavorData()
                ? LocalStorage.fetch.flavorData()
                : getFlavor()
            }
            styles={customStyles}
            options={flavorOptions}
            onChange={changeFlavor}
          />
        )}
        <Select
          components={{ DropdownIndicator }}
          value={
            LocalStorage.fetch.countryData() && showAllResults
              ? LocalStorage.fetch.countryData()
              : getCountry()
          }
          styles={customStyles}
          options={showAllResults ? countryOptions : props.CountryList}
          onChange={changeCountry}
        />
        <Flexbox marginLeft="12px" flexDirection="column">
          <UserDropdown
            profile="true"
            onClick={() => setOpenProfileDropdown(!openProfileDropdown)}
            ref={(node) => (ProfileNode.current = node)}
          >
            <img
              alt="profile-pic"
              className="profile-pic"
              src={require("static/images/profile-pic.jpg")}
            />
            <span className="profile-name">Priyanshi Tater</span>
            <img
              alt="dropdown"
              src={require("static/images/dropdown-arrow.svg")}
            />
          </UserDropdown>
          <UserDropdownContent open={openProfileDropdown}>
            <div className="option" onClick={logout}>
              Logout
            </div>
          </UserDropdownContent>
        </Flexbox>
      </Flexbox>
    </SearchContainer>
  );
}
export default withRouter(
  connect(
    ({ Country, Category, Search, Flavor }) => ({
      ...Country,
      ...Category,
      ...Search,
      ...Flavor
    }),
    (dispatch) =>
      bindActionCreators({ ...searchActions, ...flavorActions }, dispatch)
  )(Header)
);
