import React, { useState } from "react";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import { ItemContainer, ExploreRowContainer } from "./ExploreRow.styled";
import ExploreDrawer from "components/DrawerModules/ExploreDrawer";
//import ViewAll from "components/ViewAll";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import * as exploreActions from "store/Explore/actions";
import * as applicationActions from "store/Application/actions";
import BannerDrawer from "components/DrawerModules/BannerDrawer";

function ExploreRow(props) {
  //const [viewApps, setViewApps] = useState(false);
  const [editState, setEditState] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [showExploreDrawer, setShowExploreDrawer] = useState(false);
  const [showBannerDrawer, setShowBannerDrawer] = useState(false);

  const title = props.category
    ? props.ExploreList.categories[props.category].name
    : props.name;
  function EditBanner(index, data) {
    let app = { label: data.name, value: data.app.id, app: data.app };
    setDefaultData({ index: index, banner: data.icon, app: app });
    setShowBannerDrawer(true);
    setEditState(true);
  }

  return (
    <ExploreRowContainer banner={props.banner}>
      {props.isEmpty ? (
        <TitleContainer
          onClick={() => {
            setShowExploreDrawer(true);
            setEditState(true);
          }}
          className="pointer"
        >
          <img
            alt="add"
            src={require("static/images/add-black.svg")}
            style={{ margin: "0 10px 2px 0px" }}
          />
          <div>Add Category</div>
        </TitleContainer>
      ) : (
        <Flexbox>
          <ItemContainer justifyContent="flex-start">
            <TitleContainer>
              <div>
                <div>{title ? title : ""}</div>
                <div className="subtext">{`${
                  props.apps ? props.apps.length : "0"
                } ${props.banner ? "Banner" : "Application"}${
                  props.apps && props.apps.length > 0 ? "s" : ""
                }`}</div>
              </div>
            </TitleContainer>
          </ItemContainer>
          {props.banner ? null : (
            <img
              onClick={() => {
                setShowExploreDrawer(true);
                setEditState(true);
              }}
              className="ml-25 pointer"
              src={require("static/images/edit.svg")}
              alt="edit"
            />
          )}
        </Flexbox>
      )}
      <Flexbox paddingTop="20px" overflowX="auto">
        {((!props.top && props.apps && !props.apps.length) || props.banner) && (
          <Flexbox
            marginTop="10px"
            flexDirection="column"
            alignItems="center"
            onClick={() => {
              props.banner
                ? setShowBannerDrawer(true)
                : setShowExploreDrawer(true);
              setEditState(false);
            }}
          >
            <div className="add-new pointer">
              <img
                alt="plus"
                src={require("static/images/add-circle.svg")}
                className="add-new-circle"
              />
            </div>
            <div className="app-name app-edge">Add New</div>
          </Flexbox>
        )}
        {props.apps &&
          props.apps.map((app, index) => {
            return (
              <div className="app-icon-container" key={index}>
                {props.banner ? (
                  <img
                    alt="edit"
                    className="edit-section"
                    src={require("static/images/edit-section.svg")}
                    onClick={() => EditBanner(index, app)}
                  />
                ) : null}

                {app.ad && <div className="ad-icon">AD</div>}
                <Flexbox flexDirection="column" alignItems="center">
                  <img
                    alt="app"
                    src={app.icon && app.icon.mediaUrl}
                    className="application"
                  />
                  <div className="app-name">{app.name}</div>
                </Flexbox>
              </div>
            );
          })}
        {/* {apps && apps.length > 0 && (
          <div
            className="app-icon-container pointer"
            onClick={() => {
              setViewApps(!viewApps);
            }}
          >
            {!props.category && (
              <Flexbox flexDirection="column" alignItems="center">
                <div className="add-new">
                  <div className="view-all">+4</div>
                </div>
                <div className="app-name app-edge">View All</div>
              </Flexbox>
            )}
          </div>
        )} */}
      </Flexbox>

      {/* {viewApps && (
        <ViewAll
          data={props.data}
          show={viewApps}
          closeModal={() => {
            setViewApps(false);
          }}
        />
      )} */}

      {showExploreDrawer && (
        <ExploreDrawer
          apps={props.apps}
          categoryId={props.category}
          rowName={props.name}
          category={props.top}
          index={props.index}
          edit={editState}
          show={showExploreDrawer}
          closeDrawer={() => setShowExploreDrawer(false)}
        />
      )}
      {showBannerDrawer && (
        <BannerDrawer
          show={showBannerDrawer}
          closeDrawer={() => setShowBannerDrawer(false)}
          edit={editState}
          defaultData={defaultData}
          prevApps={props.apps}
        />
      )}
    </ExploreRowContainer>
  );
}

export default connect(
  ({ Explore, Category, Application }) => ({
    ...Explore,
    ...Category,
    Application
  }),
  (dispatch) =>
    bindActionCreators(
      { ...exploreActions, ...categoryActions, applicationActions },
      dispatch
    )
)(ExploreRow);
