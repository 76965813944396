import React from "react";
import { LoaderContainer } from "./Loader.styled";

function Loader(props) {
  return (
    <LoaderContainer minHeight={props.minHeight}>
      <div className="rectangle-bounce">
        <div className="rect-1"></div>
        <div className="rect-2"></div>
        <div className="rect-3"></div>
        <div className="rect-4"></div>
        <div className="rect-5"></div>
      </div>
    </LoaderContainer>
  );
}
export default Loader;
