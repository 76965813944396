import { initialState } from "./state";

export const Search = (state = initialState, action) => {
  switch (action.type) {
    case "SET_Search_LIST": {
      return Object.assign({}, state, {
        SearchList: action.data
      });
    }
    case "SET_Search_QUERY": {
      return Object.assign({}, state, {
        SearchQuery: action.data
      });
    }
    case "SET_Search_COMPLETE": {
      return Object.assign({}, state, {
        SearchComplete: action.data
      });
    }
    default:
      return state;
  }
};
