import { Application } from "services/Application";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";
import { store } from "store";

const handleError = (err) => {
  console.log("Error in Application action : ", err);
};

// Actions
export const getApplicationListRequest = (page, search) => async (dispatch) => {
  try {
    let prevList = page && store.getState().Application.ApplicationList;
    page = page ? page.split("api/v1/")[1] : false;
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Application.getApplicationList(country, page, search);
    if (data.result) {
      dispatch(
        mutation.setApplicationPage({
          count: data.count,
          totalCount: data.totalCount,
          next: data.next,
          prev: data.prev
        })
      );
      dispatch(
        mutation.setApplicationList(
          prevList ? prevList.concat(data.result) : data.result
        )
      );
    }
    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getFullApplicationListRequest = () => async (dispatch) => {
  try {
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Application.getFullApplicationList(country);
    if (data.result) {
      dispatch(mutation.setApplicationList(data.result));
    }
    return data.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createApplicationRequest = (payload) => async (dispatch) => {
  try {
    return await Application.createApplication(payload);
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateApplicationByIdRequest =
  (id, payload) => async (dispatch) => {
    try {
      let result = await Application.updateApplicationById(id, payload);
      return result;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

export const deleteApplicationByIdRequest = (id) => async (dispatch) => {
  try {
    return await Application.deleteApplicationById(id);
  } catch (err) {
    handleError(err);
  }
};

export const changeApplicationsActiveStatusRequest =
  (id, active) => async (dispatch) => {
    try {
      return await Application.changeApplicationsActiveStatus(id, active);
    } catch (err) {
      handleError(err);
    }
  };
