import React, { Component } from "react";
import Drawer from "components/common/Drawer";
// import ImageInputContainer from "components/common/ImageInputContainer";
import InputContainer from "components/common/InputContainer";
import { DrawerBody } from "static/coreStyles/global.styled";
// import SubInputContainers from "components/common/SubInputContainers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as flavorActions from "store/Flavor/actions";
// import * as uploadActions from "store/FileUpload/actions";
import swal from "sweetalert";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { platformsList } from "utility/flavorPlatformUtility";

class FlavorDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   categoryImage: props.img ? props.img : null,
      flavorID: props.id ? props.id : "",
      flavorName: props.name ? props.name : "",
      packageID: props.packageID ? props.packageID : "",
      platform: props.platform ? props.platoform : platformsList[0], // default "web"
      // platform: platformsList[0], // default "web"
      //   subCategories: props.subCategories ? props.subCategories : [],
      //   color: props.color ? props.color : "",
      //   lastSubCategory: "",
      isChanged: false,
      invalid: false,
      loader: false,
      edit: false
    };
  }

  UpdateFlavor = async (id) => {
    this.setState({ loader: true });
    // if (this.state.lastSubCategory && this.state.lastSubCategory.name) {
    //   await this.setState({
    //     subCategories: [...this.state.subCategories, this.state.lastSubCategory]
    //   });
    // }
    // let addSubCategories = this.state.subCategories.filter((subcat) => {
    //   return !subcat.id;
    // });
    // let editSubCategories = this.state.subCategories.filter((subcat) => {
    //   return subcat.id;
    // });
    let payload = {
      name: this.state.flavorName
      // platform: this.state.platform.value,
      // applicationId: `${this.state.platform.value}_${this.state.packageID}`

      //   color: this.state.color.includes("#")
      //     ? this.state.color.slice(1)
      //     : this.state.color
    };
    // if (addSubCategories.length > 0) {
    //   payload.addSubCategories = addSubCategories;
    // }
    // if (editSubCategories.length > 0) {
    //   payload.editSubCategories = editSubCategories;
    // }
    // this.state.isChanged &&
    //   (await this.props
    //     .getUploadURLRequest(this.state.categoryImage)
    //     .then((res) => {
    //       if (res.error) {
    //         swal({
    //           title: "Oops!",
    //           text: res.error.message,
    //           icon: "error"
    //         });
    //       } else if (res.result) {
    //         payload.icon = {
    //           mediaUrl: res.result.getUrl,
    //           mediaType: this.state.categoryImage.type
    //         };
    //       }
    //     }));

    this.props.updateFlavorById(id, payload).then((res) => {
      if (res.error) {
        this.setState({ loader: false });
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        this.setState({ loader: false });
        this.props.getFlavorList();
        this.props.closeDrawer();
        window.location.reload();
      }
    });
  };

  AddFlavor = async () => {
    this.setState({ loader: true });
    // if (this.state.lastSubCategory && this.state.lastSubCategory.name) {
    //   await this.setState({
    //     subCategories: [...this.state.subCategories, this.state.lastSubCategory]
    //   });
    // }
    let payload = {
      name: this.state.flavorName,
      platform: this.state.platform.value,
      applicationId: `${this.state.platform.value}_${this.state.packageID}`
      // packageID: this.state.packageID
      //   color: this.state.color.includes("#")
      //     ? this.state.color.slice(1)
      //     : this.state.color,
      //   subCategories: this.state.subCategories
    };
    // this.props.getUploadURLRequest(this.state.categoryImage).then((res) => {
    //   if (res.error) {
    //     swal({
    //       title: "Oops!",
    //       text: res.error.message,
    //       icon: "error"
    //     });
    //   } else if (res.result) {
    //     payload.icon = {
    //       mediaUrl: res.result.getUrl,
    //       mediaType: this.state.categoryImage.type
    //     };

    this.props.createFlavor(payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        this.props.getFlavorList();
        this.props.closeDrawer();
        window.location.reload();
      }
    });
  };
  //     });
  //   };

  validateForm = () => {
    if (this.props.update) {
      // Update
      if (this.state.flavorName !== "") {
        this.UpdateFlavor(this.props.update);
      }
    } else {
      // Add
      if (this.state.flavorName !== "" && this.state.packageID !== "") {
        this.AddFlavor();
      }
    }

    // if (
    //   this.state.flavorName !== "" &&
    //   this.state.packageID !== ""
    //   //   this.state.categoryImage &&
    //   //   this.state.subCategories &&
    //   //   this.state.color
    // ) {
    //   this.props.update
    //     ? this.UpdateFlavor(this.props.update)
    //     : this.AddFlavor();
    // }
    this.setState({ invalid: true });
  };

  render() {
    return (
      <div>
        <Drawer
          title={`${this.props.update ? "Edit" : "Add"} Flavor`}
          width="500px"
          show={this.props.show}
          closeDrawer={this.props.closeDrawer}
          footerText={`${this.props.update ? `Update` : `Add`} Flavor`}
          onSave={this.validateForm}
          loader={this.state.loader}
        >
          <DrawerBody>
            {/* <ImageInputContainer
              title="Category icon"
              imgUrl={require("static/images/upload.svg")}
              getImage={(value) => this.setState({ categoryImage: value })}
              img={this.props.img ? this.props.img.mediaUrl : null}
              isChanged={() => this.setState({ isChanged: true })}
              invalid={this.state.invalid && !this.state.categoryImage}
            /> */}
            <InputContainer
              title="Flavor Name"
              placeholder="Enter the Flavor name"
              getInput={(value) => {
                this.setState({ flavorName: value });
              }}
              value={this.props.name ? this.props.name : ""}
              invalid={this.state.invalid && !this.state.flavorName}
            />

            {!this.props.update && (
              <InputContainer
                title="Package ID"
                placeholder="Enter the Package ID"
                getInput={(value) => {
                  this.setState({ packageID: value });
                }}
                value={this.props.packageID ? this.props.packageID : ""}
                invalid={this.state.invalid && !this.state.flavorName}
              />
            )}

            {!this.props.update && (
              <DropdownInputContainer
                title="Platform"
                placeholder="Select the platform"
                // img
                // height={23}
                // width={35}
                options={platformsList}
                passSelectedValues={(value) => {
                  this.setState({ platform: value });
                  // this.setState(() => {
                  //   console.log("value", value, value.id, value.name);
                  //   return { platform: value };
                  // });
                }}
                default
                defaultValue={this.state.platform}
                edit={this.state.edit}
                invalid={this.state.invalid && !this.state.platform}
              />
            )}

            {/* <SubInputContainers
              title="Sub-Category "
              placeholder="Enter the Sub-category name"
              getValues={(value) => {
                this.setState({ subCategories: value });
              }}
              getLastValue={(value) => {
                this.setState({ lastSubCategory: value });
              }}
              valueList={
                this.props.subCategories ? this.props.subCategories : []
              }
              invalid={
                this.state.invalid &&
                !this.state.lastSubCategory.name &&
                !this.state.subCategories.length
              }
            />
            <InputContainer
              title="Color"
              placeholder="Choose color"
              width="50px"
              padding="6px"
              getInput={(value) => {
                this.setState({ color: value });
              }}
              color
              value={this.props.color && this.props.color}
              invalid={this.state.invalid && !this.state.color}
            /> */}
          </DrawerBody>
        </Drawer>
      </div>
    );
  }
}
export default connect(
  ({ Flavor }) => ({ ...Flavor }),
  (dispatch) => bindActionCreators({ ...flavorActions }, dispatch)
)(FlavorDrawer);
