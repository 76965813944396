import React, { Component } from "react";
import Drawer from "components/common/Drawer";
import ImageInputContainer from "components/common/ImageInputContainer";
import InputContainer from "components/common/InputContainer";
import { DrawerBody } from "static/coreStyles/global.styled";
import SubInputContainers from "components/common/SubInputContainers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import * as uploadActions from "store/FileUpload/actions";
import swal from "sweetalert";
import { getSelectedFlavorData } from "utility/flavorPlatformUtility";

class CategoryDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryImage: props.img ? props.img : null,
      categoryName: props.name ? props.name : "",
      subCategories: props.subCategories ? props.subCategories : [],
      color: props.color ? props.color : "",
      lastSubCategory: "",
      isChanged: false,
      invalid: false,
      loader: false
    };
  }

  UpdateCategory = async (id) => {
    this.setState({ loader: true });
    if (this.state.lastSubCategory && this.state.lastSubCategory.name) {
      await this.setState({
        subCategories: [...this.state.subCategories, this.state.lastSubCategory]
      });
    }
    let addSubCategories = this.state.subCategories.filter((subcat) => {
      return !subcat.id;
    });
    let editSubCategories = this.state.subCategories.filter((subcat) => {
      return subcat.id;
    });

    // Attaching sub category rank (default rank is index)
    editSubCategories = editSubCategories.map((item, index) => ({
      ...item,
      subCategoryRank: index
    }));

    let payload = {
      name: this.state.categoryName,
      // id: this.props.update,
      color: this.state.color.includes("#")
        ? this.state.color.slice(1)
        : this.state.color
    };
    if (addSubCategories.length > 0) {
      payload.addSubCategories = addSubCategories;
    }
    if (editSubCategories.length > 0) {
      payload.editSubCategories = editSubCategories;
    }
    this.state.isChanged &&
      (await this.props
        .getUploadURLRequest(this.state.categoryImage)
        .then((res) => {
          if (res.error) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res.result) {
            payload.icon = {
              mediaUrl: res.result.getUrl,
              mediaType: this.state.categoryImage.type
            };
          }
        }));

    this.props.updateCategoryByIdRequest(id, payload).then((res) => {
      if (res.error) {
        this.setState({ loader: false });
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        this.setState({ loader: false });
        this.props.getCategoryListRequest();
        this.props.closeDrawer();
        window.location.reload(); // Important otherwise create application drawer select category dropdown does not have the latest category
      }
    });
  };

  AddCategory = async () => {
    this.setState({ loader: true });
    if (this.state.lastSubCategory && this.state.lastSubCategory.name) {
      await this.setState({
        subCategories: [...this.state.subCategories, this.state.lastSubCategory]
      });
    }
    let payload = {
      name: this.state.categoryName,
      color: this.state.color.includes("#")
        ? this.state.color.slice(1)
        : this.state.color,
      subCategories: this.state.subCategories
    };
    this.props.getUploadURLRequest(this.state.categoryImage).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        payload.icon = {
          mediaUrl: res.result.getUrl,
          mediaType: this.state.categoryImage.type
        };

        this.props.createCategoryRequest(payload).then((res) => {
          if (res.error) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res.result) {
            this.props.getCategoryListRequest();
            this.props.closeDrawer();
            window.location.reload(); // Important otherwise create application drawer select category dropdown does not have the latest category
          }
        });
      }
    });
  };

  validateForm = () => {
    if (
      this.state.categoryName &&
      this.state.categoryImage &&
      this.state.subCategories &&
      this.state.color
    ) {
      this.props.update
        ? this.UpdateCategory(this.props.update)
        : this.AddCategory();
    }

    // if (
    //   this.props.update &&
    //   this.state.categoryName &&
    //   this.state.categoryImage &&
    //   this.state.subCategories &&
    //   this.state.color
    // ) {
    //   this.UpdateCategory(this.props.update);
    // } else if (
    //   this.state.categoryName &&
    //   this.state.categoryImage &&
    //   this.state.subCategories &&
    //   this.state.color
    // ) {
    //   this.AddCategory();
    // }

    // if (
    //   this.state.categoryName &&
    //   this.state.categoryImage &&
    //   this.state.subCategories &&
    //   this.state.color
    // ) {
    //   this.props.update
    //     ? this.UpdateCategory(this.props.update)
    //     : this.AddCategory();
    // }
    this.setState({ invalid: true });
  };

  render() {
    const { flavorName, platform, applicationID } = getSelectedFlavorData();

    return (
      <div>
        <Drawer
          title={`${this.props.update ? "Edit" : "Add"} Category`}
          width="500px"
          show={this.props.show}
          closeDrawer={this.props.closeDrawer}
          footerText={`${this.props.update ? `Update` : `Add`} Category`}
          onSave={this.validateForm}
          loader={this.state.loader}
        >
          <DrawerBody>
            <ImageInputContainer
              title="Category icon"
              imgUrl={require("static/images/upload.svg")}
              getImage={(value) => this.setState({ categoryImage: value })}
              img={this.props.img ? this.props.img.mediaUrl : null}
              isChanged={() => this.setState({ isChanged: true })}
              invalid={this.state.invalid && !this.state.categoryImage}
            />
            <InputContainer
              title="Category"
              placeholder="Enter the category name"
              getInput={(value) => {
                this.setState({ categoryName: value });
              }}
              value={this.props.name ? this.props.name : ""}
              invalid={this.state.invalid && !this.state.categoryName}
            />
            <SubInputContainers
              title="Sub-Category "
              placeholder="Enter the Sub-category name"
              getValues={(value) => {
                this.setState({ subCategories: value });
              }}
              getLastValue={(value) => {
                this.setState({ lastSubCategory: value });
              }}
              valueList={
                this.props.subCategories ? this.props.subCategories : []
              }
              invalid={
                this.state.invalid &&
                !this.state.lastSubCategory.name &&
                !this.state.subCategories.length
              }
            />
            <InputContainer
              title="Color"
              placeholder="Choose color"
              width="50px"
              padding="6px"
              getInput={(value) => {
                this.setState({ color: value });
              }}
              color
              value={this.props.color && this.props.color}
              invalid={this.state.invalid && !this.state.color}
            />
            <InputContainer
              title="Selected Flavor Name"
              placeholder="Selected flavor name"
              disabled
              value={flavorName}
              // invalid={this.state.invalid}
              // getInput={(value) => {
              //   this.setState({ categoryName: value });
              // }}
            />
            <InputContainer
              title="Selected Flavor Application ID"
              placeholder="Selected Application ID"
              disabled
              value={applicationID}
              // invalid={this.state.invalid}
              // getInput={(value) => {
              //   this.setState({ categoryName: value });
              // }}
            />
            <InputContainer
              title="Selected Flavor Platform"
              placeholder="Selected flavor platform"
              disabled
              value={platform}
              // invalid={this.state.invalid}
              // getInput={(value) => {
              //   this.setState({ categoryName: value });
              // }}
            />
            {/* <InputContainer
              title="Flavor"
              disabled
              placeholder="Enter the category name"
              getInput={(value) => {}}
              value={this.props.name ? this.props.name : ""}
              invalid={this.state.invalid && !this.state.categoryName}
            /> */}
          </DrawerBody>
        </Drawer>
      </div>
    );
  }
}
export default connect(
  ({ Category, FileUpload }) => ({ ...Category, ...FileUpload }),
  (dispatch) =>
    bindActionCreators({ ...categoryActions, ...uploadActions }, dispatch)
)(CategoryDrawer);
