import React, { useState, useEffect } from "react";
import { UserListingHeader } from "views/Users/Users.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import { CommonButton } from "static/coreStyles/global.styled";
import FilterModal from "components/FilterModal";
import ApplicationRow from "components/ApplicationRow/ApplicationRow";
import ApplicationDrawer from "components/DrawerModules/ApplicationDrawer";
import NoResults from "components/common/NoResults";
import PaginationLoader from "components/common/PaginationLoader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as applicationActions from "store/Application/actions";
import * as categoryActions from "store/Category/actions";
import Loader from "components/common/Loader";
import swal from "sweetalert";

function Applications(props) {
  const [showFilter, setShowFilter] = useState(false);
  const [Filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [showAppDrawer, setShowAppDrawer] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      props.ApplicationPage &&
      props.ApplicationPage &&
      props.ApplicationPage.next &&
      !pageLoader &&
      !loading
    ) {
      setPageLoader(true);
      props
        .getApplicationListRequest(props.ApplicationPage.next)
        .then((res) => {
          if (res.error) {
            setPageLoader(false);
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res) {
            setPageLoader(false);
          }
        });
    }
  };
  /**
   * Calls api to fetch list of applications and categories, scroll screen to top
   */
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    props.getApplicationListRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    props.getCategoryListRequest();
    // eslint-disable-next-line
  }, []);

  function removeFilter(filter) {
    let updatedFilter = { ...Filters };
    delete updatedFilter[filter];
    setFilters(updatedFilter);
  }
  useEffect(() => {
    setLoading(true);
    let search = Object.keys(Filters).map((item) => `&categoryIds[]=${item}`);
    search = search.join("");
    props.getApplicationListRequest(undefined, search).then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, [Filters]);
  let ApplicationList =
    props.SearchQuery &&
    props.SearchQuery.length > 2 &&
    props.SearchComplete === true
      ? props.SearchList
      : props.ApplicationList;
  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Applications{" "}
          <span>
            {ApplicationList &&
              ApplicationList.length > 0 &&
              `(` + ApplicationList.length + `)`}
          </span>
        </h1>
        <Flexbox>
          <Flexbox
            flexWrap="wrap"
            marginRight="8px"
            padding="4px"
            justifyContent="flex-end"
          >
            {Filters && Object.keys(Filters).length
              ? Object.keys(Filters).map((filter, index) => {
                  return (
                    <div key={index} className="filters">
                      {Filters[filter].name}
                      <img
                        src={require("static/images/cross.svg")}
                        alt="cross"
                        height="18px"
                        width="18px"
                        style={{ marginLeft: "8px" }}
                        onClick={() => removeFilter(filter)}
                      />
                    </div>
                  );
                })
              : null}
          </Flexbox>
          <div
            className="filter-button"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <img
              src={require("static/images/menu.svg")}
              alt="filter"
              className="filter-icon"
            />
          </div>
          <CommonButton
            app
            onClick={() => {
              setShowAppDrawer(true);
            }}
          >
            Add Application
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <UserListingHeader>
        <div style={{ width: "42%" }}>Application</div>
        <div style={{ width: "22%" }}>Category</div>
        <div style={{ width: "22%" }}>Country</div>
        <div style={{ width: "14%" }}></div>
      </UserListingHeader>
      {loading ? (
        <Loader />
      ) : ApplicationList && ApplicationList.length > 0 ? (
        ApplicationList.map((data, index) => {
          return <ApplicationRow data={data} key={index}></ApplicationRow>;
        })
      ) : (
        <NoResults
          title="applications"
          image={require("static/images/empty-applications.svg")}
        />
      )}
      {showFilter && (
        <FilterModal
          show={showFilter}
          closeModal={() => {
            setShowFilter(false);
          }}
          getFliters={(val) => setFilters(val)}
          filters={Filters}
        />
      )}
      {showAppDrawer && (
        <ApplicationDrawer
          show={showAppDrawer}
          closeDrawer={() => {
            setShowAppDrawer(false);
          }}
        />
      )}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
}
export default connect(
  ({ Application, Search }) => ({ ...Application, ...Search }),
  (dispatch) =>
    bindActionCreators({ ...applicationActions, ...categoryActions }, dispatch)
)(Applications);
