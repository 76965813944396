import React, { useState, useEffect } from "react";
import { ListRow } from "components/UserRow/UserRow.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import Switch from "components/common/Switch";
import { Flexbox } from "static/coreStyles/container.styled";
import { formatDate } from "utility/helperFunctions";
import ApplicationDrawer from "components/DrawerModules/ApplicationDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as applicationActions from "store/Application/actions";
import swal from "sweetalert";

function ApplicationRow(props) {
  function editApplication() {
    setShowAppDrawer(true);
  }
  const [showAppDrawer, setShowAppDrawer] = useState(false);
  const [activeState, setActiveState] = useState(props.data.isActive);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const countryName = props.CountryList.find(
    (country) => props.data.scope.countries[0] === country.value
  );
  useEffect(() => {
    setActiveState(props.data.isActive);
  }, [props.data.isActive]);
  function changeActiveStatus() {
    props
      .changeApplicationsActiveStatusRequest(props.data.id, !activeState)
      .then((res) => {
        if (res.error) {
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res.result) {
          props.getApplicationListRequest();
        }
      });
    setActiveState(!activeState);
  }
  function deleteApplication() {
    setDeleteLoader(true);
    props.deleteApplicationByIdRequest(props.data.id).then((res) => {
      if (!res) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
        setDeleteLoader(false);
      } else if (res) {
        props.getApplicationListRequest().then((res) => {
          if (!res) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
            setDeleteLoader(false);
          } else if (res) {
            setDeleteLoader(false);
          }
        });
      }
    });
  }
  function deleteConfirmation() {
    swal({
      title: `Are you sure you want to delete ${props.data.name}?`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        deleteApplication();
        swal(`${props.data.name} has been deleted!`, {
          icon: "success"
        });
      } else {
        swal(`${props.data.name} is safe!`);
      }
    });
  }

  const convertCategoryDataToDropdownFormat = (categoryArr) => {
    return categoryArr.map(({ _id, name, applicationId }) => ({
      label: `${name} ${applicationId}`,
      value: _id
    }));
  };

  const applicationCategoryArrayProp = convertCategoryDataToDropdownFormat(
    props.data.category
  );

  return (
    <div>
      <ListRow>
        <TitleContainer app>
          <img
            alt="application"
            src={props.data.icon && props.data.icon.mediaUrl}
            className="image"
          />
          <div>
            <div>{props.data.name}</div>
            <div className="subtext">{formatDate(props.data.createdAt)}</div>
          </div>
        </TitleContainer>
        <div style={{ width: "22%" }}>
          {props.data.category && props.data.category.name}
        </div>
        <div style={{ width: "22%" }}>
          {countryName ? countryName.label : "Global"}
        </div>
        <div style={{ width: "14%" }}>
          <Flexbox justifyContent="flex-end">
            {/* App Level Active Inactive Not Yet Implemented */}
            {/* <Switch state={activeState} changeStatus={changeActiveStatus} /> */}
            <img
              src={require("static/images/edit.svg")}
              alt="edit"
              className="pointer"
              onClick={() => editApplication()}
            />
            {deleteLoader ? (
              <img
                alt="loader"
                height="16px"
                width="16px"
                src={require("static/images/loader-block-user.svg")}
                className="delete-button fade"
              />
            ) : (
              <img
                src={require("static/images/trash.svg")}
                alt="edit"
                height="16px"
                width="16px"
                className="delete-button pointer"
                onClick={() => deleteConfirmation()}
              />
            )}
          </Flexbox>
        </div>
      </ListRow>
      {showAppDrawer && (
        <ApplicationDrawer
          edit
          id={props.data.id}
          name={props.data.name}
          description={props.data.description}
          urls={props.data.urls}
          images={props.data.images}
          appIcon={props.data.icon}
          show={showAppDrawer}
          // category={{
          //   label: props.data.category.name,
          //   value: props.data.id
          // }}
          category={applicationCategoryArrayProp}
          subCategory={
            props.data.subCategoryIds &&
            props.data.subCategoryIds.length &&
            props.data.subCategoryIds
          }
          closeDrawer={() => {
            setShowAppDrawer(false);
          }}
        />
      )}
    </div>
  );
}

export default connect(
  ({ Application, Country }) => ({ ...Application, ...Country }),
  (dispatch) => bindActionCreators({ ...applicationActions }, dispatch)
)(ApplicationRow);
