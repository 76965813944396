const setSearchList = (data) => ({
  type: "SET_Search_LIST",
  data
});

const setSearchQuery = (data) => ({
  type: "SET_Search_QUERY",
  data
});

const setSearchComplete = (data) => ({
  type: "SET_Search_COMPLETE",
  data
});
export const mutation = {
  setSearchList,
  setSearchQuery,
  setSearchComplete
};
