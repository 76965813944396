import styled from "styled-components";
import { Theme, Size, FontFamily } from "./theme.styled";

export const AppContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding-left: ${(props) => (props.navbar ? `${Size.NavbarWidth}` : "0")};
  padding-top: ${(props) => (props.navbar ? `${Size.HeaderWidth}` : "0")};
  background: ${Theme.secondaryColor};
  min-height: 100vh;
`;

export const Text = styled.p`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin || "0"};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  border-bottom: ${(props) => props.borderBottom};
  text-align: ${(props) => props.textAlign};
  opacity: ${(props) => props.opacity};
  letter-spacing: ${(props) => props.letterSpacing};
  float: ${(props) => props.float};
  display: ${(props) => props.display};
  background: ${(props) => props.background};
  padding-bottom: ${(props) => props.paddingBottom};
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-top: ${(props) => props.paddingTop};
  font-family: ${(props) => props.fontFamily};
  height: ${(props) => props.height};
  align-self: ${(props) => props.alignSelf};
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  max-width: ${(props) => props.maxWidth};
`;

export const Button = styled.button`
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  text-align: ${(props) => props.textAlign};
  opacity: ${(props) => props.opacity};
  letter-spacing: ${(props) => props.letterSpacing};
  float: ${(props) => props.float};
  display: ${(props) => props.display};
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-top: ${(props) => props.paddingTop};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius};
  max-width: ${(props) => props.maxWidth};
`;

export const CommonButton = styled.button`
  disabled: ${(props) => props.disabled};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : "40px")};
  width: ${(props) => props.width};
  border-radius: 6px;
  border: solid 1px ${Theme.primaryColor};
  background: ${(props) =>
    props.disabled ? "#ccc" : props.app ? Theme.primaryColor : "transparent"};
  font-family: Gilroy-SemiBold;
  font-size: ${Size.base};
  font-weight: 600;
  letter-spacing: -0.07px;
  text-align: center;
  color: ${(props) => (props.app ? Theme.white : Theme.primaryColor)};
  padding: 8px 26px;
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  .fade {
    animation: fadeIn 0.3s linear;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      ,
      100% {
        opacity: 1;
      }
    }
  }
  .loader {
    height: 20px;
    width: 20px;
    padding-bottom: 2px;
  }
`;

export const TitleContainer = styled.div`
  width: ${(props) =>
    props.app ? "42%" : props.ad ? "45%" : props.user ? "32%" : "unset"};
  display: flex;
  font-family: ${FontFamily.SemiBold};
  align-items: center;
  .image {
    object-fit: contain;
    border-radius: ${(props) => (props.app || props.ad ? "6px" : "100%")};
    width: 40px;
    height: 40px;
    margin-right: 11px;
  }
  .subtext {
    padding-top: 3px;
    opacity: 0.5;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    letter-spacing: -0.06px;
    color: #222222;
  }
  .ad-marker {
    position: absolute;
    top: 10px;
    left: 41px;
    width: 20px;
    height: 14px;
    border-radius: 6px;
  }
`;

export const Banner = styled.div`
  height: 145px;
  width: 100%;
  background-color: rgba(11, 95, 227, 0.05);
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 7px;
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.base};
  font-weight: 600;
  color: ${Theme.black};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .uploaded-banner {
    height: 145px;
    width: 100%;
    position: relative;
    border-radius: 6px;
    object-fit: cover;
    display: ${(props) => !props.show && "none"};
  }
  .custom-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: ${(props) => props.show && "none"};
  }
  .banner {
    object-fit: contain;
    border-radius: 6px;
    width: 100%;
    height: 145px;
  }
  .banner-placeholder {
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
    text-decoration: none;
    font-family: ${FontFamily.Medium};
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #222222;
    position: relative;
    display: ${(props) => props.show && "none"};
  }
  .banner-placeholder-image {
    margin-bottom: 10px;
  }
  .banner-upload {
    font-family: ${FontFamily.SemiBold};
    color: ${Theme.primaryColor};
    cursor: pointer;
  }

  .banner-edit-button {
    position: absolute;
    font-family: ${FontFamily.SemiBold};
    font-size: ${Size.xs};
    font-weight: 600;
    letter-spacing: -0.05px;
    color: #222222;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    top: 12px;
    right: 24px;
    display: ${(props) => !props.show && "none"};
  }
  .banner-edit-icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
`;

export const BlurBackground = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(4, 22, 50, 0.7);
  justify-content: ${(props) => (props.drawer ? "flex-end" : "center")};
  align-items: center;
`;

export const DrawerCTA = styled.button`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.remove ? Theme.white : Theme.primaryColor};
  font-family: ${FontFamily.SemiBold};
  font-size: ${Size.lg};
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: -0.08px;
  color: ${(props) => (props.remove ? Theme.primaryColor : Theme.white)};
  border: 1px solid ${Theme.primaryColor};
`;

export const UploadTitle = styled.div`
  opacity: ${(props) =>
    props.opacity ? props.opacity : props.multi ? "0.7" : "0.5"};
  font-family: ${FontFamily.Medium};
  font-size: ${(props) =>
    props.size ? props.size : props.multi ? "10px" : Size.sm};
  font-weight: 500;
  color: ${(props) => (props.multi ? Theme.primaryColor : "#222222")};
  margin-top: ${(props) => (props.multi ? "4px" : "0")};
  margin-bottom: ${(props) => props.marginBottom};
`;
export const ErrorMessage = styled.div`
  font-family: ${FontFamily.Medium};
  font-size: ${Size.xs};
  font-weight: 500;
  color: rgba(255, 0, 0, 0.6);
  line-height: 1.6;
  margin-left: 4px;
`;
export const DrawerBody = styled.div`
  padding: 30px;
  height: calc(100% - 191px);
  overflow-y: auto;
`;

export const ButtonFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.toggle ? Theme.primaryColor : "rgba(11, 95, 227, 0.1)"};
  border-radius: 6px;
  margin: 0 4px 20px 4px;
  padding: 2px 8px;
  font-size: ${Size.xs};
  font-family: ${FontFamily.Regular};
  font-weight: ${(props) => (props.toggle ? "400" : "600")};
  color: ${(props) => (props.toggle ? Theme.white : Theme.primaryColor)};
`;
