import React, { useEffect } from "react";
import "./App.scss";
import AppRouter from "router/appRouter";
import { AppContainer } from "static/coreStyles/global.styled";
import { withRouter } from "react-router-dom";
import SideNavbar from "components/common/SideNavbar";
import Header from "components/common/Header";
import { storage as LocalStorage } from "services/config/storage";

function App(props) {
  const navbarRequired = path => {
    if (path.includes("login")) return false;
    else return true;
  };
  const isNavbarRequired = navbarRequired(props.location.pathname);
  useEffect(() => {
    !LocalStorage.fetch.authToken() && props.history.push("/login");
    // eslint-disable-next-line
  }, []);
  return (
    <AppContainer navbar={isNavbarRequired}>
      {isNavbarRequired && <Header />}
      {isNavbarRequired && <SideNavbar />}
      <AppRouter />
    </AppContainer>
  );
}

export default withRouter(App);
