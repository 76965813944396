import styled from "styled-components";
import { FontFamily } from "static/coreStyles/theme.styled";

export const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => (props.drag ? "100%" : "50px")};
  height: ${props => (props.drag ? "100px" : "50px")};
  border-radius: 6px;
  border: ${props =>
    props.bg
      ? "unset"
      : props.invalid
      ? "solid 1px rgba(255, 0, 0, 0.60)"
      : "solid 1px rgba(26, 65, 106, 0.12)"};
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
  cursor: pointer;
  position: relative;
  .upload-text {
    font-family: ${FontFamily.SemiBold};
    font-size: 13px;
    color: #222222;
    margin-top: 10px;
  }
  .custom-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  input[type="file"]:hover {
    opacity: 0;
    color: transparent;
  }
  .upload-subtext {
    opacity: 0.4;
    font-family: ${FontFamily.Medium};
    font-size: 10px;
    color: #212121;
    margin-top: 4px;
  }
`;

export const UploadedImageContainer = styled.div`
  position: relative;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(160, 181, 205, 0.2);
  visibility: ${props => !props.show && "hidden"};
  .uploaded-img {
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 6px;
    object-fit: cover;
  }
  .cross {
    position: absolute;
    top: -7px;
    right: -7px;
    width: 17px;
    height: 17px;
  }
`;
export const UploadImagePlaceholder = styled.img`
  visibility: ${props => props.show && "hidden"};
  position: absolute;
`;
