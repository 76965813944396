import { Api } from "./config/request";

export const getUserList = (country, page) => {
  return Api.getRequest(
    `${
      page
        ? page
        : `users/${
            country === "Global"
              ? `?isGlobal=true`
              : country === "All"
              ? ""
              : `?countryCode=${country}`
          }`
    }`
  );
};

export const createUser = (payload) => {
  return Api.postRequest(`users/`, payload);
};
export const updateUserById = (id, payload) => {
  return Api.putRequest(`users/${id}`, payload);
};
export const updateUserBlockStatus = (id, block) => {
  return Api.postRequest(`users/${id}/${block ? "block" : "unblock"}`);
};
export const exportUsers = () => {
  return Api.postRequest("users/csv");
};
export const User = {
  getUserList,
  createUser,
  updateUserById,
  updateUserBlockStatus,
  exportUsers
};
