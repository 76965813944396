import React, { useState, useEffect } from "react";
import { InputField } from "components/common/InputContainer/InputContainer.styled";
import { UploadTitle, ErrorMessage } from "static/coreStyles/global.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { SubContainer } from "./SubInputContainers.styled";

function SubInputContainers(props) {
  const [NewSubCategory, setNewSubCategory] = useState({ name: "" });
  const [SubCategories, setSubCategories] = useState(props.valueList);

  useEffect(() => {
    props.getValues(SubCategories);
    // eslint-disable-next-line
  }, [SubCategories]);
  useEffect(() => {
    props.getLastValue(NewSubCategory);
    // eslint-disable-next-line
  }, [NewSubCategory]);

  function addSubcategory() {
    if (NewSubCategory.name) {
      setSubCategories([...SubCategories, NewSubCategory]);
      setNewSubCategory({ name: "" });
    }
  }

  function updateSubCategory(value, index) {
    let SubCategoryArray = Array.from(SubCategories);
    SubCategoryArray[index].name = value;
    setSubCategories(SubCategoryArray);
  }

  function removeSubcategory(i) {
    setSubCategories([
      ...SubCategories.slice(0, i),
      ...SubCategories.slice(i + 1, SubCategories.length)
    ]);
  }

  return (
    <Flexbox flexDirection="column" marginBottom="20px">
      {props.title && (
        <Flexbox>
          <UploadTitle>{props.title}</UploadTitle>
          {props.invalid && <ErrorMessage>Required*</ErrorMessage>}
        </Flexbox>
      )}
      {SubCategories &&
        SubCategories.length > 0 &&
        SubCategories.map((value, index) => {
          return (
            <SubContainer key={index}>
              <InputField
                width="calc(100% - 70px)"
                value={SubCategories[index].name}
                onChange={(e) => updateSubCategory(e.target.value, index)}
              />
              <div
                className="button-holder minus pointer"
                onClick={() => removeSubcategory(index)}
              >
                <img alt="subtract" src={require("static/images/minus.svg")} />
              </div>
            </SubContainer>
          );
        })}
      <SubContainer>
        <InputField
          placeholder={props.placeholder}
          width="calc(100% - 70px)"
          value={NewSubCategory.name}
          onChange={(e) => setNewSubCategory({ name: e.target.value })}
          invalid={props.invalid}
        />
        <div
          className="button-holder plus pointer"
          onClick={() => addSubcategory()}
        >
          <img alt="add" src={require("static/images/add-circle.svg")} />
        </div>
      </SubContainer>
    </Flexbox>
  );
}
export default SubInputContainers;
