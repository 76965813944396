import React, { useEffect, useRef } from "react";
import { DrawerContent, DrawerHead, DrawerFooter } from "./Drawer.styled";
import { BlurBackground, DrawerCTA } from "static/coreStyles/global.styled";
import { Flexbox } from "static/coreStyles/container.styled";

function Drawer(props) {
  // eslint-disable-next-line
  const DrawerNode = useRef(null);
  const BackgroundNode = useRef(null);
  function handleClick(e) {
    if (
      BackgroundNode.current &&
      BackgroundNode.current.contains(e.target) &&
      DrawerNode.current &&
      !DrawerNode.current.contains(e.target)
    ) {
      hideDrawer();
    }
  }
  function hideDrawer() {
    props.closeDrawer();
  }
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("click", handleClick, false);
    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("click", handleClick, false);
      document.body.style.overflow = "auto";
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BlurBackground
      show={props.show}
      drawer
      ref={(node) => (BackgroundNode.current = node)}
    >
      <DrawerContent
        ref={(node) => (DrawerNode.current = node)}
        width={props.width}
      >
        <DrawerHead>
          {props.title}
          <img
            alt="cross"
            src={require("static/images/cancel-circle.svg")}
            className="pointer"
            height="20px"
            width="20px"
            onClick={() => {
              hideDrawer();
            }}
          />
        </DrawerHead>
        {props.children}
        <DrawerFooter>
          {props.remove ? (
            <Flexbox justifyContent="space-between">
              <DrawerCTA
                width="calc(50% - 10px)"
                onClick={() => {
                  props.onSave();
                }}
              >
                {props.loader ? (
                  <img
                    alt="loader"
                    src={require("static/images/loader-button.svg")}
                    className="loader"
                  />
                ) : (
                  props.footerText
                )}
              </DrawerCTA>
              <DrawerCTA
                remove
                width="calc(50% - 10px)"
                onClick={() => {
                  props.onRemove();
                }}
              >
                {props.removeLoader ? (
                  <img
                    alt="loader"
                    src={require("static/images/loader-block-user.svg")}
                    className="loader"
                  />
                ) : (
                  "Remove"
                )}
              </DrawerCTA>
            </Flexbox>
          ) : (
            <DrawerCTA
              onClick={() => {
                props.onSave();
              }}
            >
              {props.loader ? (
                <img
                  alt="loader"
                  src={require("static/images/loader-button.svg")}
                  className="loader"
                />
              ) : (
                props.footerText
              )}
            </DrawerCTA>
          )}
        </DrawerFooter>
      </DrawerContent>
    </BlurBackground>
  );
}
export default Drawer;
