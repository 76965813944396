import React, { useState, useEffect } from "react";
import {} from "./AdvertisementDrawer.styled";
import Drawer from "components/common/Drawer";
import { DrawerBody } from "static/coreStyles/global.styled";
import { adStickers } from "utility/advertisementsUtility";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { storage as LocalStorage } from "services/config/storage";
import * as advertisementActions from "store/Advertisement/actions";
import * as applicationActions from "store/Application/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from "sweetalert";

function AdvertisementDrawer(props) {
  const [name, setName] = useState(props.name ? props.name : "");
  const [sticker, setSticker] = useState(props.sticker ? props.sticker : "");
  const [country, setCountry] = useState([
    LocalStorage.fetch.countryData()
      ? LocalStorage.fetch.countryData()
      : props.CountryList[0]
  ]);
  const [invalid, setInvalid] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    props.ApplicationList &&
      props.ApplicationList.length < 21 &&
      props.getFullApplicationListRequest();
    // eslint-disable-next-line
  }, []);
  let ActiveApplicationList =
    props.ApplicationList && props.ApplicationList.length
      ? props.ApplicationList.reduce(function (filtered, ad) {
          if (ad.isActive) {
            filtered.push({
              name: ad.name,
              url: ad.icon && ad.icon.mediaUrl,
              id: ad.id
            });
          }
          return filtered;
        }, [])
      : [];
  let ApplicationList =
    props.ApplicationList && props.ApplicationList.length
      ? props.ApplicationList.map((ad) => {
          return {
            name: ad.name,
            url: ad.icon && ad.icon.mediaUrl,
            id: ad.id
          };
        })
      : [];

  function CreateAd() {
    setLoader(true);
    let countries = country.map((val) => val.value);
    let payload = {
      marker: {
        mediaUrl: sticker,
        mediaType: "image/svg+xml"
      },
      appId: name
    };
    if (countries.includes("Global")) {
      payload.scope = {
        isGlobal: true
      };
    } else {
      payload.scope = {
        isGlobal: false,
        countries: countries
      };
    }

    props.createAdvertisementRequest(payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
        setLoader(false);
      } else if (res.result) {
        props.getAdvertisementListRequest();
        setLoader(false);
        props.closeDrawer();
      }
    });
  }

  function UpdateAd() {
    setLoader(true);
    let payload = {
      marker: {
        mediaUrl: sticker,
        mediaType: "image/svg+xml"
      }
    };

    props.updateAdvertisementByIdRequest(props.id, payload).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
        setLoader(false);
      } else if (res.result) {
        props.getAdvertisementListRequest();
        setLoader(false);
        props.closeDrawer();
      }
    });
  }
  function validateForm() {
    if (name && sticker && country) {
      props.edit ? UpdateAd() : CreateAd();
    } else {
      setInvalid(true);
    }
  }

  return (
    <Drawer
      title={`${props.edit ? "Edit" : "Add"} Advertisement`}
      width="500px"
      show={props.show}
      closeDrawer={props.closeDrawer}
      footerText={`${props.edit ? "Update" : "Add"} Advertisement`}
      onSave={validateForm}
      loader={loader}
    >
      <DrawerBody>
        <DropdownInputContainer
          title="Application Name"
          placeholder="Select the application name"
          img
          height={30}
          width={30}
          options={props.edit ? ApplicationList : ActiveApplicationList}
          passSelectedValues={(value) => {
            setName(value.id);
          }}
          value="id"
          defaultValue={props.name ? props.name : ""}
          edit={props.edit}
          disabled={props.edit}
          invalid={invalid && !name}
        />
        <DropdownInputContainer
          title="Advertisement sticker"
          placeholder="Select the advertisement sticker"
          img
          height={23}
          width={35}
          options={adStickers}
          passSelectedValues={(value) => {
            setSticker(value.id);
          }}
          value="url"
          defaultValue={sticker}
          edit={props.edit}
          invalid={invalid && !sticker}
        />
        <DropdownInputContainer
          title="Country"
          placeholder="Select the country"
          defaultValue={country}
          options={props.CountryList}
          passSelectedValues={(value) => setCountry(value)}
          default
          disabled={props.edit}
          multi
          invalid={invalid && !country}
        />
      </DrawerBody>
    </Drawer>
  );
}
export default connect(
  ({ Application, Country }) => ({ ...Application, ...Country }),
  (dispatch) =>
    bindActionCreators(
      { ...applicationActions, ...advertisementActions },
      dispatch
    )
)(AdvertisementDrawer);
