import React, { useState, useEffect } from "react";
import { CategoryRowContainer } from "./CategoryRow.styled";
import { Flexbox } from "static/coreStyles/container.styled";
import { TitleContainer } from "static/coreStyles/global.styled";
import Switch from "components/common/Switch";
import { withRouter } from "react-router-dom";
import CategoryDrawer from "components/DrawerModules/CategoryDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import swal from "sweetalert";

function CategoryRow(props) {
  let data = props.data;
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [activeState, setActiveState] = useState(props.data.isActive);
  useEffect(() => {
    setActiveState(props.data.isActive);
  }, [props.data.isActive]);
  function changeActiveStatus() {
    props
      .changeCategoryActiveStatusRequest(data.id, !activeState)
      .then((res) => {
        if (res.error) {
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res.result) {
          props.getCategoryListRequest();
        }
      });
    setActiveState(!activeState);
  }

  function redirectToDetail(data) {
    props.history.push({
      pathname: `/categories/${data.id}`
    });
  }
  function editCategory(e) {
    setShowCategoryDrawer(true);
    e.stopPropagation();
  }
  return (
    <div>
      <CategoryRowContainer
        onClick={() => {
          redirectToDetail(data);
        }}
        color={!data.color.includes("#") ? `#` + data.color : data.color}
      >
        <Flexbox justifyContent="space-between" alignItems="center">
          <TitleContainer>
            <img
              alt={data.name}
              src={data.icon && data.icon.mediaUrl}
              className="image"
            />
            <div>
              <div className="pointer">{data.name}</div>
              <div className="subtext">
                {data.subCategories && data.subCategories.length > 0
                  ? data.subCategories.length +
                    ` Sub ${
                      data.subCategories.length === 1
                        ? "Category"
                        : "Categories"
                    }  • `
                  : `No Sub Categories  • `}

                {data.topApps && data.topApps.length > 0
                  ? data.topApps.length +
                    ` ${
                      data.topApps.length === 1 ? "Application" : "Applications"
                    }`
                  : ` No Applications`}
              </div>
            </div>
          </TitleContainer>
          <Flexbox>
            <Switch state={activeState} changeStatus={changeActiveStatus} />
            <img
              src={require("static/images/edit.svg")}
              alt="edit"
              className="ml-25 pointer"
              height="13px"
              width="13px"
              onClick={(e) => editCategory(e)}
            />
          </Flexbox>
        </Flexbox>
        {data.topApps && data.topApps.length > 0 && (
          <div>
            <Flexbox margin="38px 0 20px">
              <div className="bar"></div>
              <h5 className="top-apps">
                Top {data.topApps.length} Applications
              </h5>
            </Flexbox>
            <Flexbox>
              {data.topApps.map((application, index) => {
                return (
                  <img
                    alt={application.name}
                    src={application.icon && application.icon.mediaUrl}
                    key={index}
                    className="application"
                  />
                );
              })}
            </Flexbox>
          </div>
        )}
      </CategoryRowContainer>
      {showCategoryDrawer && (
        <CategoryDrawer
          show={showCategoryDrawer}
          closeDrawer={() => {
            setShowCategoryDrawer(false);
          }}
          update={data._id}
          name={data.name}
          img={data.icon}
          color={data.color}
          subCategories={data.subCategories.map((item) => {
            return { name: item.name, id: item.id };
          })}
        />
      )}
    </div>
  );
}

export default connect(
  ({ Category }) => ({ ...Category }),
  (dispatch) => bindActionCreators({ ...categoryActions }, dispatch)
)(withRouter(CategoryRow));
