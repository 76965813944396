import React, { useState, useEffect } from "react";
import { CommonButton } from "static/coreStyles/global.styled";
import { Flexbox, Container } from "static/coreStyles/container.styled";
import CategoryRow from "components/CategoryRow";
import CategoryDrawer from "components/DrawerModules/CategoryDrawer";
import NoResults from "components/common/NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "store/Category/actions";
import Loader from "components/common/Loader";
import PaginationLoader from "components/common/PaginationLoader";
import swal from "sweetalert";
import { ReactSortable } from "react-sortablejs";

function Categories(props) {
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);
  const [reordered, setReordered] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      props.CategoryPage &&
      props.CategoryPage.next &&
      !pageLoader &&
      !loading
    ) {
      setPageLoader(true);
      props.getCategoryListRequest(props.CategoryPage.next).then((res) => {
        if (res.error) {
          setPageLoader(false);
          swal({
            title: "Oops!",
            text: res.error.message,
            icon: "error"
          });
        } else if (res) {
          setPageLoader(false);
        }
      });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    props.getCategoryListRequest().then((res) => {
      if (res) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  let CategoryList =
    props.SearchQuery &&
    props.SearchQuery.length > 2 &&
    props.SearchComplete === true
      ? props.SearchList
      : props.CategoryList;

  const [categoryListState, setCategoryListState] = useState([...CategoryList]);

  useEffect(() => {
    setCategoryListState([...CategoryList]);
  }, [CategoryList]);

  const updateState = (event) => {
    setCategoryListState((prev) => {
      const oldArray = [...prev];
      return oldArray.map((item, index) => ({ ...item, categoryRank: index }));
    });
    setIsChanged(true);
  };

  const saveCategoriesOrder = () => {
    // Send only necessary payload, also removes chosen and selected keys added by react-sortablejs
    const payload = {
      category: categoryListState.map((item) => {
        const keysArr = Object.keys(item);
        keysArr.forEach((key) => {
          if (
            key !== "id" &&
            key !== "applicationId" &&
            key !== "name" &&
            key !== "color" &&
            key !== "categoryRank" &&
            key !== "icon"
          ) {
            delete item[`${key}`];
          }
        });
        return item;
      })
    };
    setPageLoader(true);
    props.updateCategoryOrder(payload).then((res) => {
      if (res.error) {
        setPageLoader(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res) {
        setPageLoader(false);
        props.getCategoryListRequest().then((res) => {
          if (res) {
            setLoading(false);
            setIsChanged(false);
          }
        });
        // window.location.reload();
      }
    });
  };

  return (
    <Container>
      <Flexbox justifyContent="space-between" alignItems="flex-end">
        <h1>
          Categories{" "}
          <span>
            {CategoryList &&
              CategoryList.length > 0 &&
              `(` + CategoryList.length + `)`}
          </span>
        </h1>
        <Flexbox>
          <CommonButton
            marginRight={"20px"}
            disabled={!isChanged}
            app
            onClick={saveCategoriesOrder}
          >
            Save Order
          </CommonButton>

          <CommonButton
            app
            onClick={() => {
              setShowCategoryDrawer(true);
            }}
          >
            Add Category
          </CommonButton>
        </Flexbox>
      </Flexbox>
      <>
        {loading ? (
          <Loader />
        ) : CategoryList && CategoryList.length > 0 ? (
          <ReactSortable
            list={categoryListState}
            setList={setCategoryListState}
            onChange={() => setReordered(true)}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            onEnd={updateState}
            className="reorder"
          >
            {categoryListState.map((category, index) => {
              return <CategoryRow data={category} key={index}></CategoryRow>;
            })}
          </ReactSortable>
        ) : (
          <NoResults
            title="categories"
            image={require("static/images/empty-categories.svg")}
          />
        )}
      </>
      {showCategoryDrawer && (
        <CategoryDrawer
          show={showCategoryDrawer}
          closeDrawer={() => {
            setShowCategoryDrawer(false);
          }}
        />
      )}
      {pageLoader && <PaginationLoader />}
    </Container>
  );
}
export default connect(
  ({ Category, Search }) => ({ ...Category, ...Search }),
  (dispatch) => bindActionCreators({ ...categoryActions }, dispatch)
)(Categories);
