import styled from "styled-components";
import { Theme, FontFamily, Size } from "static/coreStyles/theme.styled";

export const ModalContent = styled.div`
  width: ${props => (props.banner ? "660px" : "460px")};
  border-radius: 6px;
  background-color: ${Theme.white};
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 4px solid #f7f8f8;
  font-family: ${FontFamily.Bold};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.09px;
  color: #222222;
  .modal-subtitle {
    opacity: 0.5;
    font-family: ${FontFamily.Medium};
    font-size: ${Size.xs};
    font-weight: 500;
    letter-spacing: -0.05px;
    color: #222222;
  }
`;
