import { Explore } from "services/Explore";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = err => {
  console.log("Error in Explore action : ", err);
};

// Actions
export const getExploreListRequest = () => async dispatch => {
  try {
    let country = LocalStorage.fetch.countryData();
    country = country ? country.value : "Global";
    let data = await Explore.getExploreList(country);
    if (data.result) {
      data.result.apps = data.dictionary.apps;
      data.result.categories = data.dictionary.categories;
      dispatch(mutation.setExploreList(data.result));
    }
    return data;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const updateExploreRequest = payload => async dispatch => {
  try {
    return await Explore.updateExplore(payload);
  } catch (err) {
    handleError(err);
    return err;
  }
};
