import { initialState } from "./state";

export const Category = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CATEGORY_LIST": {
      return Object.assign({}, state, {
        CategoryList: action.data
      });
    }
    case "SET_CATEGORY_DETAIL": {
      return Object.assign({}, state, {
        CategoryDetail: action.data
      });
    }
    case "SET_CATEGORY_PAGE": {
      return Object.assign({}, state, {
        CategoryPage: action.data
      });
    }
    case "SET_CATEGORY_LIST_OF_ALL_FLAVORS": {
      console.log("SET_CATEGORY_LIST_OF_ALL_FLAVORS");
      return Object.assign({}, state, {
        CategoryListOfAllFlavors: action.data
      });
    }
    default:
      return state;
  }
};
