import React from "react";
import { NoResultsContainer } from "./NoResults.styled";
import { Flexbox } from "static/coreStyles/container.styled";

function NoResults(props) {
  return (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 220px)"
      flexDirection="column"
    >
      <NoResultsContainer>
        <img src={props.image} alt="empty-state" className="empty-state-img" />
        <div className="heading">No {props.title} right now</div>
        <div className="subheading">
          There are no {props.title} to be displayed as of now.
        </div>
      </NoResultsContainer>
    </Flexbox>
  );
}
export default NoResults;
