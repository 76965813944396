import { Api } from "./config/request";

export const getApplicationList = (country, page,search) => {
  return Api.getRequest(
    `${
      page
        ? page
        : `apps/${
            country === "Global"
              ? `?isGlobal=true`
              : country === "All"
              ? ""
              : `?countryCode=${country}`
          }`
    }${search ? search : ''}`
  );
};

export const getFullApplicationList = (country) => {
  return Api.getRequest(
    `apps/${
      country === "Global"
        ? `?isGlobal=true`
        : country === "All"
        ? ""
        : `?countryCode=${country}`
    }&pageSize=100000`
  );
};
export const createApplication = (payload) => {
  return Api.postRequest(`apps/`, payload);
};

export const updateApplicationById = (id, payload) => {
  return Api.putRequest(`apps/${id}`, payload);
};

export const changeApplicationsActiveStatus = (id, active) => {
  return Api.postRequest(`apps/${id}/${active ? "activate" : "deactivate"}`);
};

export const deleteApplicationById = (id) => {
  return Api.deleteRequest(`apps/${id}`);
};

export const Application = {
  getApplicationList,
  createApplication,
  updateApplicationById,
  changeApplicationsActiveStatus,
  getFullApplicationList,
  deleteApplicationById
};
