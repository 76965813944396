import React, { useState, useEffect } from "react";
import Drawer from "components/common/Drawer";
import ImageInputContainer from "components/common/ImageInputContainer";
import InputContainer from "components/common/InputContainer";
import DropdownInputContainer from "components/common/DropdownInputContainer";
import { DrawerBody } from "static/coreStyles/global.styled";
import DragInputContainer from "components/common/DragInputContainer";
import { storage as LocalStorage } from "services/config/storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uploadActions from "store/FileUpload/actions";
import * as applicationActions from "store/Application/actions";
import * as categoryActions from "store/Category/actions";
import swal from "sweetalert";

function ApplicationDrawer(props) {
  const [loader, setLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [description, setDescription] = useState(
    props.description ? props.description : ""
  );
  const [websiteUrl, setWebsiteUrl] = useState(
    props.urls ? props.urls.website : ""
  );
  const [playstoreUrl, setPlaystoreUrl] = useState(
    props.urls ? props.urls.playstore : ""
  );
  const [deeplinkUrl, setDeeplinkUrl] = useState(
    props.urls ? props.urls.deeplink : ""
  );
  const [name, setName] = useState(props.name ? props.name : "");
  const [category, setCategory] = useState([
    props.categoryDetailScreen
      ? props.categoryDetailScreen
      : props.category
      ? props.category
      : {}
  ]);

  // const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [subCategoryOptions, setSubcategoryOptions] = useState([]);
  const [appIcon, setAppIcon] = useState(props.appIcon ? props.appIcon : "");
  const [featureImage, setFeatureImage] = useState(
    props.images && props.images.featuredApp ? props.images.featuredApp : ""
  );
  const [banners, setBanners] = useState(
    props.images ? props.images.banners : ""
  );
  const [hasBannerChanged, setHasBannerChanged] = useState(false);
  const [hasFeatureImageChanged, setHasFeatureImageChanged] = useState(false);

  const [country, setCountry] = useState([
    LocalStorage.fetch.countryData()
      ? LocalStorage.fetch.countryData().value === "All"
        ? ""
        : LocalStorage.fetch.countryData()
      : props.CountryList[0]
  ]);

  let urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  useEffect(() => {
    props.CategoryListOfAllFlavors &&
      !props.CategoryListOfAllFlavors.length &&
      props.getCategoryListOfAllFlavors();
  });

  /**
   * this is called when user wants to update Application,
   * it sets subCategories option according to the selected category
   */
  // useEffect(() => {
  //   if (props.edit || props.categoryDetailScreen) {
  //     let value = props.CategoryList.find((category) =>
  //       props.categoryDetailScreen
  //         ? props.categoryDetailScreen.value === category.id
  //         : props.category.value === category.id
  //     );
  //     value &&
  //       setSubcategoryOptions(
  //         value.subCategories &&
  //           value.subCategories.length &&
  //           value.subCategories.map((subCat) => {
  //             return { label: subCat.name, value: subCat.id };
  //           })
  //       );
  //   }
  // }, [
  //   props.edit,
  //   props.category,
  //   props.CategoryList,
  //   props.categoryDetailScreen
  // ]);

  // useEffect(() => {
  //   props.subCategory &&
  //     setSubcategory(
  //       subCategoryOptions &&
  //         subCategoryOptions.filter(
  //           (option) => props.subCategory.indexOf(option.value) > -1
  //         )
  //     );
  // }, [subCategoryOptions, props.subCategory]);

  let categoryOptions = props.CategoryListOfAllFlavors.map(
    ({ _id, name, applicationId }) => ({
      label: `${name} ${applicationId}`,
      value: _id
      // categoryId: _id
    })
  );

  // let ans = [];

  // let categoryOptions = props.CategoryListOfAllFlavors.reduce(function (
  //   filtered,
  //   category
  // ) {
  //   // if (category.isActive) {
  //   filtered.push({
  //     label: category.name,
  //     value: category._id,
  //     subCategories: category.subCategories
  //   });
  //   // }
  //   return filtered;
  // },
  // []);

  function selectCategory(value) {
    setSubcategory([]);
    setSubcategoryOptions(
      value.subCategories &&
        value.subCategories.length &&
        value.subCategories.map((subCat) => {
          return { label: subCat.name, value: subCat.id };
        })
    );
    setCategory(value);
  }

  // From -> arr = [{label: "", value: "", categoryId: ""}]
  // To -> arr = ["categoryId_a", ...]
  const convertToBackendFormat = (arr) => {
    const result = arr.map(({ value }) => value);
    return result;
  };

  async function CreateApp() {
    setLoader(true);
    let countries = country.map((val) => val.value);
    let subCategoryIds = subcategory.map((subCat) => subCat.value);
    let payload = {
      name: name,
      icon: appIcon,
      urls: {},
      categoryIds: convertToBackendFormat(category),
      // categoryId: category.value,
      // subCategoryIds: subCategoryIds,
      images: {}
    };
    if (countries.includes("Global")) {
      payload.scope = {
        isGlobal: true
      };
    } else {
      payload.scope = {
        isGlobal: false,
        countries: countries
      };
    }
    if (featureImage) {
      payload.images.featuredApp = await uploadFeaturedImage(featureImage);
    }
    if (description) {
      payload.description = description;
    }
    if (websiteUrl) {
      payload.urls.website = websiteUrl;
    }
    if (playstoreUrl) {
      payload.urls.playstore = playstoreUrl;
    }
    if (deeplinkUrl) {
      payload.urls.deeplink = deeplinkUrl;
    }
    if (banners.length) {
      payload.images.banners = await uploadBanners(banners);
    }
    props.createApplicationRequest(payload).then((res) => {
      if (res.error) {
        setLoader(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        props.getApplicationListRequest().then(() => {
          setLoader(false);
          props.closeDrawer();
        });
        props.categoryDetailScreen &&
          props
            .getCategoryDetailRequest(props.categoryDetailScreen.value)
            .then(() => {
              setLoader(false);
            });
      }
    });
  }

  async function UpdateApp() {
    setLoader(true);
    let payload = {
      name: name,
      icon: appIcon,
      urls: {
        website: websiteUrl
      },
      categoryIds: convertToBackendFormat(category),
      images: {
        banners: banners.length
          ? !hasBannerChanged
            ? banners
            : await uploadBanners(banners)
          : []
      },
      description: description ? description : null
    };
    if (featureImage) {
      payload.images.featuredApp = hasFeatureImageChanged
        ? await uploadFeaturedImage(featureImage)
        : featureImage;
    }
    if (playstoreUrl) {
      payload.urls.playstore = playstoreUrl;
    }
    if (deeplinkUrl) {
      payload.urls.deeplink = deeplinkUrl;
    }

    props.updateApplicationByIdRequest(props.id, payload).then((res) => {
      if (res.error) {
        setLoader(false);
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else {
        props.getApplicationListRequest().then(() => {
          setLoader(false);
          props.closeDrawer();
        });
      }
    });
  }

  function uploadAppIcon(value) {
    if (!value) {
      setAppIcon(null);
      return;
    }
    props.getUploadURLRequest(value).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        setAppIcon({
          mediaUrl: res.result.getUrl,
          mediaType: value.type
        });
      }
    });
  }

  async function uploadFeaturedImage(value) {
    if (!value) {
      setFeatureImage(value);
      return;
    }
    let val = await props.getUploadURLRequest(value).then((res) => {
      if (res.error) {
        swal({
          title: "Oops!",
          text: res.error.message,
          icon: "error"
        });
      } else if (res.result) {
        setFeatureImage({
          mediaUrl: res.result.getUrl,
          mediaType: value.type
        });
        return {
          mediaUrl: res.result.getUrl,
          mediaType: value.type
        };
      }
    });
    return val;
  }
  function uploadBanners(values) {
    let resultUrl = [];
    return Promise.all(
      values.map(async (imageFile, index) => {
        await props.getUploadURLRequest(imageFile).then((res) => {
          if (res.error) {
            swal({
              title: "Oops!",
              text: res.error.message,
              icon: "error"
            });
          } else if (res.result) {
            resultUrl[index] = {
              mediaUrl: res.result.getUrl,
              mediaType: imageFile.type
            };
          }
        });
        setBanners(resultUrl);
        return resultUrl[index];
      })
    );
  }
  function validateForm() {
    if (
      name &&
      appIcon &&
      ((websiteUrl && websiteUrl.match(urlRegex)) ||
        (playstoreUrl && playstoreUrl.match(urlRegex)) ||
        (deeplinkUrl && deeplinkUrl.match(urlRegex))) &&
      // category &&
      // category.length &&
      // subcategory &&
      // subcategory.length &&
      country
    ) {
      props.edit ? UpdateApp() : CreateApp();
    } else {
      setInvalid(true);
    }
  }

  return (
    <div>
      <Drawer
        title={`${props.edit ? "Edit" : "Add"} Application`}
        width="500px"
        show={props.show}
        closeDrawer={props.closeDrawer}
        footerText={`${props.edit ? "Update" : "Add"} Application`}
        onSave={validateForm}
        loader={loader}
      >
        <DrawerBody>
          <ImageInputContainer
            title="Application Icon"
            imgUrl={require("static/images/upload.svg")}
            getImage={uploadAppIcon}
            img={props.appIcon ? props.appIcon.mediaUrl : ""}
            invalid={invalid && !appIcon}
          />
          <DragInputContainer
            title="Featured Image"
            imgUrl={require("static/images/banner-placeholder.svg")}
            uploadText="Drag your image here, or browse"
            uploadSubtext="Supports: JPG, PNG"
            getImageInput={(val) => {
              setHasFeatureImageChanged(true);
              setFeatureImage(val);
            }}
            img={featureImage ? featureImage.mediaUrl : null}
          />
          <DragInputContainer
            title="Application Banner/s"
            imgUrl={require("static/images/banner-placeholder.svg")}
            uploadText="Drag your images here, or browse"
            uploadSubtext="Supports: JPG, PNG"
            multi
            getMultiImageInput={(val) => {
              setHasBannerChanged(true);
              setBanners(val);
            }}
            img={
              banners && banners.length
                ? banners.map((banner) => banner.mediaUrl)
                : ""
            }
          />
          <InputContainer
            title="Application Name"
            placeholder="Enter the application name"
            getInput={(value) => {
              setName(value);
            }}
            value={name}
            invalid={invalid && !name}
          />
          <InputContainer
            title="Application description"
            placeholder="Write something about the Application"
            textbox
            getInput={(value) => {
              setDescription(value);
            }}
            value={description}
          />
          <InputContainer
            title="Website URL"
            placeholder="Enter the website url"
            getInput={(value) => {
              setWebsiteUrl(value);
            }}
            value={websiteUrl}
            invalid={
              (websiteUrl && !websiteUrl.match(urlRegex)) ||
              (invalid && (!websiteUrl || !playstoreUrl || !deeplinkUrl))
            }
          />
          <InputContainer
            title="Playstore URL"
            placeholder="Enter the playstore url"
            getInput={(value) => {
              setPlaystoreUrl(value);
            }}
            value={playstoreUrl}
            invalid={
              (playstoreUrl && !playstoreUrl.match(urlRegex)) ||
              (invalid && (!websiteUrl || !playstoreUrl || !deeplinkUrl))
            }
          />
          <InputContainer
            title="Deeplink URL"
            placeholder="Enter the deeplink url"
            getInput={(value) => {
              setDeeplinkUrl(value);
            }}
            value={deeplinkUrl}
            invalid={
              (deeplinkUrl && !deeplinkUrl.match(urlRegex)) ||
              (invalid && (!websiteUrl || !playstoreUrl || !deeplinkUrl))
            }
          />
          <DropdownInputContainer
            title="Category"
            placeholder="Select the Category"
            passSelectedValues={(value) => {
              setCategory(value);
            }}
            options={categoryOptions}
            disabled={false}
            // disabled={props.edit || props.categoryDetailScreen}
            edit={props.edit}
            noAlter
            default={props.categoryDetailScreen}
            defaultValue={
              props.categoryDetailScreen
                ? props.categoryDetailScreen
                : props.category
            }
            invalid={invalid && !category}
            multi
          />

          <DropdownInputContainer
            title="Country"
            placeholder="Select the country"
            defaultValue={country}
            options={props.CountryList}
            passSelectedValues={(value) => setCountry(value)}
            default
            multi
            disabled={props.edit}
            invalid={invalid && !country}
          />
          {/* <DropdownInputContainer
            title="Sub Category"
            placeholder="Select the Sub Category"
            passSelectedValues={(value) => {
              setSubcategory(value);
            }}
            multi
            edit={props.edit}
            options={subCategoryOptions}
            disabled={props.edit}
            values={subcategory}
            invalid={invalid && subcategory && !subcategory.length}
          /> */}
        </DrawerBody>
      </Drawer>
    </div>
  );
}
export default connect(
  ({ Category, FileUpload, Application, Country }) => ({
    ...Category,
    ...FileUpload,
    ...Application,
    ...Country
  }),
  (dispatch) =>
    bindActionCreators(
      { ...uploadActions, ...applicationActions, ...categoryActions },
      dispatch
    )
)(ApplicationDrawer);
